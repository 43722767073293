import { alpha, makeStyles, withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/img/logo.png';
import logoSmall from '../../assets/img/logoSmall.png';
import category from '../../assets/img/category.svg';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button'
import UserLoggedIn from '@material-ui/icons/AccountCircle'
import url, { allowedAduanaPoints, colors, iconsPath, supportNumber } from '../../config'
import '../../assets/css/layout.css'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close'
import DehazeIcon from '@material-ui/icons/Dehaze';
import ListIcon from '@material-ui/icons/ListAlt'
import { AccountBalanceWallet, Apps, ArrowDropDown, Assignment, AttachMoney, Close, EmojiSymbols, Group, Home, Inbox, LiveHelp, LocalOffer, LocalShipping, LocationOn, MonetizationOn, Person, PostAdd, Receipt, Score, Search, Store } from '@material-ui/icons';
import { Avatar, Badge, CircularProgress, Collapse, Divider, Drawer, Fab, SvgIcon } from '@material-ui/core';
import LoginRegister from './LoginRegister';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
// import { v4 as uuidv4 } from 'uuid'
import PageVisibility from 'react-page-visibility'
import HelpIcon from '@material-ui/icons/Help'
import InfoIcon from '@material-ui/icons/Info'
import SendIcon from '@material-ui/icons/Send'
import { Skeleton } from '@material-ui/lab';
import { update_search_params_action, update_products_action } from '../../redux/actions/productsAction';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import whatsap_icon from '../../assets/img/whatsap_icon.png'
import { update_login_action } from '../../redux/actions/loginAction';
import reduceDecimalValue from '../../tools/productTools';
import { update_categories_action } from '../../redux/actions/categoriesAction';
import search_menu_image from '../../assets/promotional_banner/search_menu.png'
import SelectRegionModal from './SelectRegionModal';

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `2px solid ${colors.whiteAppbar}`,
      padding: '0 4px',
      backgroundColor: alpha(colors.secondary,1)
    },
  }))(Badge);

  const StyledBadgePrimary = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `2px solid ${colors.whiteAppbar}`,
      padding: '0 4px',
      backgroundColor: alpha(colors.primary,1)
    },
  }))(Badge);

  const StyledTabBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '9px 7px',
      color: colors.whiteAppbar,
      backgroundColor: alpha(colors.secondary,1)
    },
  }))(Badge);

  const StyledTabBadgePrimary = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '9px 7px',
      color: colors.whiteAppbar,
      backgroundColor: alpha(colors.primary,1)
    },
  }))(Badge);

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)'
    },
    list: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important'
    }
  })((props) => (
    <Menu
      elevation={0}
      transitionDuration={0}
      classes={{
        list: {paddingBottom: '0px !important'},
      }}
      disableScrollLock={true}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: colors.primary,
        [theme.breakpoints.down('xs')]: {
            // backgroundColor: colors.whiteAppbar,
        }
    },
    appBarTab: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: colors.whiteAppbar,
        height: '50px',
        top: 'auto',
        bottom: '0',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    tabToolbar: {
        minHeight: '50px',
        height: '50px',
        padding: '0px',
        alignItems: 'center'
    },
    tabElement: {
        flex: 1, 
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    tabIcon: {
        height: '28px',
        width: '28px'
    },
    mainDivResponsive: {
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1536px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    mainToolbar: {
        minHeight: '75px',
        paddingLeft: '0px',
        paddingRight: '0px',
        [theme.breakpoints.down('xs')]: {
            minHeight:'50px',
            height: '50px'    
        },
        
    },
    smallToolbar: {
        height: '50px',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '10px',
            paddingRight: '10px',    
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
          },
    },
    toolbar: theme.mixins.toolbar,
    logoHolder: {
        backgroundColor: colors.whiteAppbar,
        paddingRight: '26px',
        display: 'flex',
        height: '35px',
        borderTopRightRadius: '17.5px',
        borderBottomRightRadius: '17.5px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: '33px',
            paddingRight: '10px',
        },
    },
    extraPaddingDiv: {
        flex: 0,
        height: '35px',
        backgroundColor: colors.whiteAppbar,
        [theme.breakpoints.down('sm')]: {
            flex: 1,
        },
        [theme.breakpoints.down('xs')]: {
            height: '33px'
        },
        [theme.breakpoints.up('lg')]: {
            flex: 1
        },
    },
    appBarContainer: {
        display: 'flex',
        height: '35px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            height: '33px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1140px'
        },
    },
    logo: {
        cursor: 'pointer',
        height: '21px',
        width: 'auto', 
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'   
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginLeft: '0px',
            height: '19px'
        },
    },
    logoSmall: {
        cursor: 'pointer',
        height: '21px',
        width: 'auto', 
        marginLeft: '10px',
        display: 'none',
        [theme.breakpoints.down('sm')]: { 
            marginLeft: '10px',
            display: 'block'  
        },
        [theme.breakpoints.down('xs')]: { 
            display: 'none'  
        },
    },
    
    searchMenuHolder: {
        backgroundColor: colors.whiteAppbar,
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
            backgroundColor: 'transparent',
            width: '90%',
            marginLeft: '5%',
        }
    },
    closeSearchMenuButton: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            backgroundColor: colors.secondary,
            padding: '4px',
            color: colors.whiteAppbar,
        }
    },
    closeSearchMenuSmall: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'space-between'
        }
    },
    categoriesHolder: {
        flex: 1,
        maxHeight: 'calc(100vh - 200px)', 
        overflowY: 'scroll',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: colors.whiteAppbar,
            width: '90%',
            flex: 0.9,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10px',
            maxHeight: '100%'
        }
    },
    hideSmall: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'        
        }
    },
    SearchAndFilter:{
        display: 'flex',
        flex: 1,
        height: '35px',
        marginLeft: '20px',
        marginRight: '20px',
        alignItems: 'center',
        borderRadius: '17.5px',
        backgroundColor: 'white',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '40px',
            marginRight: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    SearchAndFilterInside:{
        display: 'flex',
        flex: 1,
        height: '35px',
        alignItems: 'center',
        borderRadius: '17.5px',
        backgroundColor: colors.whiteAppbar,
    },
    searchMenu: {
        backgroundColor:'white',
        borderRadius: '3px',
        borderTopLeftRadius: '17.5px',
        borderTopRightRadius: '17.5px',
        borderWidth: '0px',
        width: 'calc(100% - 425px)',
        paddingBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(90% - 172px)',
        },
        [theme.breakpoints.up('lg')]: {
            width: '673.5px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(58, 195, 218, 0.95)',
            maxWidth: '100%',
            maxHeight: '100%',
            top: '0px !important',
            left: '0px !important',
            transformOrigin: '0px 0px 0px'
        },
    },
    search: {
        display: 'flex',
        height: '35px', 
        flex: 1,
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
    },
    searchIcon: {
        padding: '0px 4px',
        height: '30px',
        width: '30px',
        marginRight: '4px',
        marginLeft: '4px',
        fontSize: '1.3rem !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: colors.secondary,
        borderRadius: '50%',
        '&:hover': {
            opacity: '0.8',
          },
    },
    searchIconSmall: {
        padding: '0px 4px',
        height: '26px',
        width: '26px',
        marginRight: '4px',
        marginLeft: '-10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: colors.secondary,
        borderRadius: '50%',
        '&:hover': {
            opacity: '0.8',
          },
    },
    homeIcon: {
        padding: '0px 0px',
        height: '30px',
        width: '30px',
        marginRight: '4px',
        marginLeft: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.secondary,
        cursor: 'pointer',
        backgroundColor: colors.whiteAppbar,
        borderRadius: '50%',
        '&:hover': {
            opacity: '0.8',
          },
    },
    selectedIcon: {
        backgroundColor: colors.primary,
    },
    selectedCustomIcon: {
        color: `${colors.primary} !important`,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        height: 22,
        //transformOrigin: 'top left',
        //transform: 'scale(0.75)',
        fontSize: '12px',
        fontWeight: 400,
        paddingRight: '6px',
        paddingLeft: `6px`,
        transition: theme.transitions.create('width'),
        width: 'calc(100%)',
        '&::placeholder': {
            textAlign: 'center',
            color: '#6D6E71',
            opacity: 1, /* Firefox */
            letterSpacing: '6px' 
        },
        /* or, for legacy browsers */
        
        '&::-webkit-input-placeholder': {
            textAlign: 'center'
        },
        '&:-moz-placeholder': { /* Firefox 18- */
            textAlign: 'center'  
        }, 
        '&::-moz-placeholder': {  /* Firefox 19+ */
            textAlign: 'center'  
        },
        '&:-ms-input-placeholder': {  
            textAlign: 'center',
            color: colors.secondaryDark 
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '18px'
        },
    },
    navbarButton: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.35),
        },
        height: '30px',
        marginRight: 4
    },
    
    sellButtonFab: {
        display: 'none',
        backgroundColor: colors.primary,
        '&:hover': {
          fontSize: '21px',
          backgroundColor: colors.primary,
        },
        zIndex: '1100',
        fontSize: '20px',
        color: 'white',
        height: '85px',
        fontWeight: 600,
        borderRadius: 0,
        borderTopLeftRadius: '100%',
        width: '85px',
        // [theme.breakpoints.down('xs')]: {
        //     display: 'grid',
        //     position: 'fixed',
        //     bottom: '0px',
        //     //left: '50%',
        //     //transform: 'translateX(-50%)'
        //     right: '0px'
        //   },
    },
    optionIcon: {
        color: colors.secondary,
        height: '22px',
        width: 'auto'
    },
    profileAvatar: {
        width: '21px',
        height: '21px',
        borderRadius: '50%'
    },
    filterIcon:{
        height: '18px',
        color: colors.whiteAppbar
    },
    filterText: {
        fontSize: '12px', 
        color: colors.whiteAppbar,
        marginRight: '0px'
    },
    filterTextSmall: {
        fontSize: '14px', 
        fontWeight: '600',
        color: colors.whiteAppbar,
        marginRight: '0px',
        whiteSpace: 'nowrap'
    },
    sellIconFab: {
        //fontSize: 'inherit',
        color: 'white',
        marginTop: '5px',
        marginBottom: '-5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        //transform: 'rotate(45deg)'
    },
    sellIconOptions: {
        color: colors.secondary,
        //transform: 'rotate(45deg)'
    },
    dropDownIcon: {
        color: 'rgba(0, 0, 0, 0.87)',
        zIndex: "10",
        cursor: 'pointer'
    },
    label: {
        textTransform: 'capitalize',
        fontWeight: 600,
    },
    darkGrayButton: {
        backgroundColor: colors.secondary,
        '&:hover': {
          backgroundColor: alpha(colors.secondary, 0.85),
        },
        color: '#ffffff',
        height: 35,
        marginRight: 4,
        [theme.breakpoints.down('md')]: {
            marginRight: 0    
        },
    },
    filterButton: {
        padding:0,
        minWidth:38,
        borderRadius: '3px',
        backgroundColor: colors.secondary
    },
    categoriesIcon: {
        color: "#ffffff",
        width: "1.4em",
        height: "1.4em",
        padding: '2px'
    },
    categoriesButton: {
        minWidth: 38,
        backgroundColor: colors.secondary,
        borderRadius: '3px',
        padding: '7px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
          },
        "&:hover": { 
            backgroundColor: alpha(colors.secondary, 0.85),
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
    },
    optionsIcon: {
        color: colors.secondary,
        width: "0.9em",
        height: "0.9em",
    },
    optionsButton: {
        minWidth: 38,
        backgroundColor: colors.whiteAppbar,
        borderRadius: '50%',
        marginRight: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
          },
        "&:hover": { 
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    searchSmallButton: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f9f9f9',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            height: '32px',
            cursor: 'pointer'
        }
    },
    loginButton: {
        color: colors.secondary,
        backgroundColor: colors.whiteAppbar,
        marginRight: '-16px',
        fontWeight: 600,
        borderRadius: '3px',
        textTransform: 'capitalize',
        height: '35px',
        "&:hover": { 
            color: colors.primary,
            backgroundColor: colors.whiteAppbar
        },
        [theme.breakpoints.down('md')]: {
            marginRight: '-16px'
          },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },
    optionButtonsHolder: {
        backgroundColor: colors.whiteAppbar,
        paddingLeft: '16px',
        paddingRight: '10px',
        display: 'flex',
        height: '35px',
        borderTopLeftRadius: '17.5px',
        borderBottomLeftRadius: '17.5px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    optionButtonsHolderSmall: {
        backgroundColor: colors.whiteAppbar,
        paddingLeft: '16px',
        display: 'flex',
        height: '35px',
        borderTopLeftRadius: '17.5px',
        borderBottomLeftRadius: '17.5px',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
            height: '33px'
        },
    },
    cartPointsText: {
        fontWeight: '600',
        fontSize: '11px',
        paddingRight: '10px',
        textAlign: 'center'
    },
    optionButton: {
        borderRadius: '50%',
        padding: '5.5px !important',
        backgroundColor: 'transparent',
        transition: 'box-shadow 250ms ease',
        marginRight: '0px',
        "&:hover": { 
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },
    sellButton: {
        borderRadius: '20px',
        backgroundColor: 'transparent',
        transition: 'box-shadow 250ms ease',
        "&:hover": { 
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },

    //Categories Style
    categoryDrawerStyle:{
        width: 250
    },
    drawerTextStyle:{
        display: "block",
        width:"100%", 
        textAlign:"center", 
        lineHeight: "48px", 
        fontWeight:"600", 
        fontSize:"16px",
        color: "#ffffff", 
        backgroundColor: colors.primary, 
        height: "48px"
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: colors.redError,
        }
    },
    inputHolder: {
        display: 'flex',
        width: '55px',
        marginLeft: '5px',
        backgroundColor:colors.whiteAppbar, 
        height: '23px', 
        borderRadius: '3px'
    },
    inputHolderDrawer: {
        display: 'flex',
        width: '60px',
        marginRight: '5px',
        backgroundColor:colors.secondary, 
        height: '25px',
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    currencyHolder: {
        display: 'flex',
        height: '25px',
        backgroundColor:colors.secondary, 
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    inputRootLogin: {
        width: '100%',
    },
    inputInputLogin: {
        height: 22,
        fontSize: '11px',
        fontWeight: 400,
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: colors.secondary,
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: colors.secondary,
            textAlign: 'center',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: colors.secondary,
            textAlign: 'center',
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: colors.secondary,
            textAlign: 'center',
          },
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    categoriesAndPriceFilter: {
        display: 'flex',
        flexDirection: 'column'
    },
    filterPopup: {
        // display:'flex',
        paddingBottom: '6px',
        backgroundColor: colors.secondary,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    filterDrawerIcon: {
        color:colors.secondary, 
        height: '18px', 
        width: '18px', 
        margin: '10px auto 10px 2px',
        [theme.breakpoints.down('sm')]: {
            margin: '10px 5px 10px 10px'
          },
    },
    filterDrawerText: {
        writingMode: 'vertical-rl',
        rotate: '180deg',
        textOrientation: 'mixed',
        color:colors.secondary,
        fontWeight: '800'
    },
    stateFilterHolder: {
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
          },
    },
    stateFilterFirst: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block'
        //   },
    },
    stateFilterSecond: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block'
        //   },
    },
    asNewFilter: {
        padding:'0px 3px 0px 0px',
        [theme.breakpoints.down('xs')]: {
            padding:'0px 3px 0px 22px',
          },
    },
    drawer: {
        width: '240px',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
      },
      drawerPaper: {
        position: 'fixed',
        width: '240px',
        overflowY: 'scroll',
        zIndex: '1001',
        height: 'calc(100% - 75px)',
        marginTop: '75px',
        borderRadius: '5px',
        borderRight: '0px solid',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
      },
      drawerFilter: {
        backgroundColor: colors.containerGradientFirst,
        borderRadius: '5px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      drawerInfo: {
        backgroundColor: colors.containerGradientFirst,
        borderRadius: '5px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      drawerContainer: {
        overflow: 'auto',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    selectedProvince: {
        height: '11px',
        width: '11px',
        border: `solid 1px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        marginLeft: '1px'
    },
    unselectedProvince: {
        height: '13px',
        width: '13px',
        border: `solid 1px ${colors.secondary}`
    },
    filterDivider: {
        backgroundColor: colors.secondary, 
        height: '130px', 
        width: '1px', 
        marginLeft: '25px', 
        marginRight: '25px', 
        marginTop: '40px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    filterPriceAndPoints: {
        marginLeft: 'auto',
        display: 'flex'
    },
    discountAndFastDelivery: {
        marginRight: 'auto',
        marginLeft: '5px'
    },
    categoryActive: {
        backgroundColor: `${colors.primary} !important`,
        color: 'white'
    },
    categoryElement: {
        fontSize: '11px', 
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px', 
            fontWeight: '700',
        }
    },
    menuIconUserOptions: {
        width: '18px', 
        height: '18px',
        marginRight: '5px'
    },
    listText: {
        fontWeight: '800',
        fontSize: '14px',
        marginLeft: '20px',
        marginRight: '20px',
        [theme.breakpoints.up('md')]:{
            paddingLeft: '240px',
        },
        [theme.breakpoints.down('md')]:{
            marginLeft: '10px',
            marginRight: '10px',
        },
    }
}))

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();
    let screenWidth = window.innerWidth
  
    return (
      <Collapse in={true}>
        {children}
      </Collapse>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
  

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}
  
  // One item component
  // selected prop will be passed
  
  // All items component
  // Important! add unique key
  
  
const skeleton = [1,2,3,4,5,6,7,8,9,10,11,12,13,14]
// const provinces = [
//     'Todas las Provincias',
//     'Artemisa',
//     'Camagüey',
//     'Ciego de Ávila',
//     'Cienfuegos',
//     'Granma',
//     'Guantánamo',
//     'Holguín',
//     'Isla de la Juventud',
//     'La Habana',
//     'Las Tunas',
//     'Matanzas',
//     'Mayabeque',
//     'Pinar del Río',
//     'Sancti Spíritus',
//     'Santiago de Cuba',
//     'Villa Clara'
// ]
const ITEM_HEIGHT = 48;

const currencies = ["TODAS", "USD"]

const Navbar = ({props, registerPromotion, homePage, favoritesPage, cartPage, accountPage, sellPage}) => {
    const classes  = useStyles()
    const location = useLocation()
    const locationUseRef = useRef()
    locationUseRef.current = location
    const history = useHistory()
    const categories = useSelector(state => state.categories_store.categories)
    const [searchField, setSearchField] = useState("")
    const [minPriceError, setMinPriceError] = useState("")
    const [minPriceErrorOpen, setMinPriceErrorOpen] = useState(false)
    const [maxPriceError, setMaxPriceError] = useState("")
    const [maxPriceErrorOpen, setMaxPriceErrorOpen] = useState(false)
    const [minPointsError, setMinPointsError] = useState("")
    const [minPointsErrorOpen, setMinPointsErrorOpen] = useState(false)
    const [maxPointsError, setMaxPointsError] = useState("")
    const [maxPointsErrorOpen, setMaxPointsErrorOpen] = useState(false)
    const [categoryDrawer, setCategoryDrawer] = useState({left: false, right: false})

    const [paramsLoading, setParamsLoading] = useState(true)

    const [anchorElCategories, setAnchorElCategories] = useState(null);
    const openCategories = Boolean(anchorElCategories);
    const [anchorElDrawer, setAnchorElDrawer] = useState(null);
    const openDrawer = Boolean(anchorElDrawer);

    const [anchorElUserOptions, setAnchorElUserOptions] = useState(null);
    const openUserOptions = Boolean(anchorElUserOptions);

    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const openFilter = Boolean(anchorElFilter);

    const [openSuccesSnack, setOpenSuccesSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openRegisterSuccesSnack, setOpenRegisterSuccesSnack] = useState(false)
    const [openRegisterApiErrorSnack, setOpenRegisterApiErrorSnack] = useState(false)

    const [snackUserLoginProps, setSnackUserLoginProps] = useState({open: false, severity: 'warning', closingTime: 100000, circularProgress: false, text: ""})
    const loadedRef = useRef(false)

    const [categoriesLoading, setCategoriesLoading] = useState(true)

    const [openSelectRegionModal, setOpenSelectRegionModal] = useState(false)

    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const searchParamsToExecuteUseRef = useRef()
    searchParamsToExecuteUseRef.current = searchParamsToExecute
    const socket_store_selector = useSelector((state) => state.socket_store)
    const cart_store_selector = useSelector((state) => state.cart_store)
    const userLogged = useSelector((state) => state.user_store.user)
    const accessToken = useSelector((state) => state.user_store.accessToken)
    const dispatch = useDispatch()

    const [size, setSize] = useState(0)

    React.useEffect(() => {
        if(homePage){
            const region = window.localStorage.getItem('region')
            if(!region) setOpenSelectRegionModal(true)
        }
    }, []);

    useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const onBackPressedDetect = (event) => {
        if(detectChange()){
            dispatch(update_products_action([], 0, false))
            const newParams = getQueryParams()
            newParams.params.onBackPressed = true 
            dispatch(update_search_params_action(newParams.params))   
        }
    }
    // #region UseEffect
    React.useEffect(() => {
        window.addEventListener('popstate', (event) => {
              onBackPressedDetect(event)
        })
        return (
            window.removeEventListener('popstate', (event) => {
                onBackPressedDetect(event)  
            })
        )
    }, []);

    React.useEffect(() => {
        if(registerPromotion){
            dispatch(update_login_action(true))
        }
    }, []);

    useEffect(() => {
        if(accessToken && !userLogged){
            loadedRef.current = true
            setSnackUserLoginProps({
                open: true,
                severity: 'warning',
                circularProgress: true,
                closingTime: 100000,
                text: "Actualizando perfil de usuario"
            })
        }
        else{
            if(userLogged && loadedRef.current){
                loadedRef.current = false
                setSnackUserLoginProps({
                    open: true,
                    severity: 'success',
                    circularProgress: false,
                    closingTime: 2000,
                    text: "Perfil obtenido correctamente"
                })
            }
        }
    }, [userLogged])

    const getQueryParams = () => {
        const search = locationUseRef.current.search
        const queryParams = new URLSearchParams(search)
        let queryHasParameters = false
        let newParams = {...searchParamsToExecute}
        if (queryParams.has('searchPhrase')) {
            newParams.searchPhrase = queryParams.get('searchPhrase')
            setSearchField(queryParams.get('searchPhrase'))
            queryHasParameters = true
        }
        else{
            setSearchField("")
            newParams.searchPhrase = ''
        }
        if (queryParams.has('minPrice')) {
            newParams.minPrice = queryParams.get('minPrice')
            queryHasParameters = true
        }
        if (queryParams.has('maxPrice')) {
            newParams.maxPrice = queryParams.get('maxPrice')
            queryHasParameters = true
        }
        if (queryParams.has('minPoints')) {
            newParams.minPoints = queryParams.get('minPoints')
            queryHasParameters = true
        }
        if (queryParams.has('maxPoints')) {
            newParams.maxPoints = queryParams.get('maxPoints')
            queryHasParameters = true
        }
        if (queryParams.has('fastDelivery')) {
            newParams.fastDelivery = false
            queryHasParameters = true
        }
        if (queryParams.has('possibleDiscount')) {
            newParams.possibleDiscount = false
            queryHasParameters = true
        }
        if (queryParams.has('categoryId')) {
            newParams.selected = queryParams.get('categoryId')
            queryHasParameters = true
        }
        else{
            newParams.selected = "0"
        }
        if (queryParams.has('region')) {
            newParams.selectedProvince = queryParams.get('region')
            queryHasParameters = true
        } 
        return {
            queryHasParameters: queryHasParameters,
            params: newParams
        }
    }

    useEffect(() => {
        const getInitialQueryParams = () => {
            try{
                const newParams = getQueryParams() 
                if(newParams.queryHasParameters){
                    newParams.onBackPressed = false
                    dispatch(update_search_params_action(newParams.params))
                }
                setParamsLoading(false)
            } catch(error){
                console.log(`ENCONTRE ERROR ${error}`)
                setParamsLoading(false)
            }
        }
        getInitialQueryParams() 
    }, [])

    useEffect(() => {
        const fetchCategories = async () => {
            if(categories.length > 0) {
                setCategoriesLoading(false)
                return
            }
            try{
                let result = await fetch(`${url}/api/categoriesImport?limit=100`)
                let dataResult = await result.json()
                let dataResultWithAll = [{_id: "0", name: "Todos", iconUrl: category}]
                dispatch(update_categories_action(dataResultWithAll.concat(dataResult.categories)))
            } catch(error){
                console.log({error})
            }
            setCategoriesLoading(false)
        }
        fetchCategories() 
    }, [])


    useEffect(() => {
        if(!homePage) return
        manageParams()
    }, [searchParamsToExecute])
    
    // #endregion

    const detectChange = () => {
        if(!homePage) return
        const search = locationUseRef.current.search
        const queryParams = new URLSearchParams(search)
        let oldParams = {...searchParamsToExecuteUseRef.current}
        if (queryParams.has('searchPhrase')) {
            if(oldParams.searchPhrase !== queryParams.get('searchPhrase')){
                return true
            }
        }
        else{
            if(oldParams.searchPhrase !== '') {
                return true
            }
        }
        if (queryParams.has('categoryId')) {
            if(oldParams.selected !== queryParams.get('categoryId')){
                return true
            }
        }
        else{
            if(oldParams.selected !== '0'){
                return true
            }
        } 
        return false
    }

    const manageParams = () => {
        const queryParams = {}
        let hasParameters = false
        if (searchField) {
            queryParams["searchPhrase"] = searchField
            hasParameters = true
        }
        if(searchParamsToExecute.minPrice){
            if(parseInt(searchParamsToExecute.minPrice)){
                queryParams["minPrice"] = searchParamsToExecute.minPrice
                hasParameters = true
            }
        }
        if(searchParamsToExecute.maxPrice){
            if(parseInt(searchParamsToExecute.maxPrice)){
                queryParams["maxPrice"] = searchParamsToExecute.maxPrice
                hasParameters = true
            }
        }
        if(searchParamsToExecute.minPoints){
            if(parseInt(searchParamsToExecute.minPoints)){
                queryParams["minPoints"] = searchParamsToExecute.minPoints
                hasParameters = true
            }
        }
        if(searchParamsToExecute.maxPoints){
            if(parseInt(searchParamsToExecute.maxPoints)){
                queryParams["maxPoints"] = searchParamsToExecute.maxPoints
                hasParameters = true
            }
        }
        if(!(searchParamsToExecute.selected === "0")){
            queryParams["categoryId"] = searchParamsToExecute.selected
            hasParameters = true
        }
        if(searchParamsToExecute.selectedProvince !== "Todas las Provincias"){
            queryParams["region"] = searchParamsToExecute.selectedProvince
            hasParameters = true
        }
        if(hasParameters){
            let params = new URLSearchParams(queryParams)
            // history.replace({
            //     search: params.toString(),
            // })
            if(!searchParamsToExecute.onBackPressed){
                history.push({
                    path: '/',
                    search: params.toString(),
                })
            }
        }
        else{
            let params = new URLSearchParams()
            // history.replace({
            //     search: params.toString(),
            // // })
            if(!searchParamsToExecute.onBackPressed){
                history.push({
                    path: '/',
                    search: params.toString(),
                })
            }
        }
    }

    // #region Snack Events
    const handleCloseSuccesSnack = () => {
        setOpenSuccesSnack(false)
        if(registerPromotion){
            history.push("/")
        }
        else{
            window.location.reload(false);
        }
    }
    const handleCloseUserLoginPropsSnack = () => {
        setSnackUserLoginProps({
            ...snackUserLoginProps,
            open: false
        })
    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }
    const handleCloseRegisterSuccesSnack = () => {
        setOpenRegisterSuccesSnack(false)
    }
    const handleCloseRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(false)
    }
    const OpenSuccessSnack = () => {
        setOpenRegisterSuccesSnack(false)
        setOpenSuccesSnack(true)
    }
    const OpenApiErrorSnack = () => {
        setOpenApiErrorSnack(true)
    }
    const OpenRegisterSuccessSnack = () => {
        setOpenRegisterSuccesSnack(true)
    }
    const OpenRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(true)
    }
    // #endregion

    // #region LOGIN METHODS
    const userLoggedOut = () => {
        if(!userLogged) return
        const userId = userLogged._id
        const subscription = userLogged.subscription
        socket_store_selector.socket.emit("removeSocketFromImportUser", {
            userId: userId,
            subscription: subscription
        })
        
        window.localStorage.removeItem('user')
        window.history.replaceState(null, null, "/");
        window.location.reload(false);
    }
    const handleClickOpen = () => {
        dispatch(update_login_action(true));
    };
    // #endregion

    // #region ONCLICK
    const toAccountPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/account")
    }

    const toMyProductsPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/myproducts")
    }
    const toMyDeliversPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push(`/delivers/${userLogged._id}`)
    }
    const toOrdersPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/orders")
    }

    const toFavoritesPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/favorites")
    }
    const toLegalInformationPage = (event) => {
        window.scrollTo(0,0)
        history.push("/legalinformation")
    }
    const toHelpPage = (event) => {
        window.scrollTo(0,0)
        history.push("/help")
    }
    const toAdminUsersPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/adminUsers")
    }
    const toAdminProductsPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/adminProducts")
    }
    const toAdminCategoriesPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/adminCategories")
    }
    const toAdminProvidersPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/adminDistributors")
    }
    const toFinancePage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/finances")
    }

    const toCurrencyExchangePage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/currencyExchange")
    }

    const toCartPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/cart")
    }

    const toDashBoardPage = (event) => {
        if(!accessToken){
            dispatch(update_login_action(true))
            return
        }
        window.scrollTo(0,0)
        history.push("/selldashboard")
    }
    const toHelpWhatsap = () => {
        window.open(`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`, '_blank')
    }

    const handleClickUserOptions = (event) => {
        setAnchorElUserOptions(event.currentTarget);
    };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };

    const handleKeyDown = (e) => {
        //!!TO AVOID LETTER FROM GOING TO MENU FOCUS
        e.stopPropagation()
        //!!!
        if(homePage) return
        if(e.key === 'Enter'){
          e.preventDefault()
          if(size < 640){
            handleCloseFilter()
          }
          handleSearchClick()
        }
    }
    
    const handleSearchClick = (searchWord) => {
        let search = searchWord !== undefined? searchWord: searchField
        if(homePage){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.searchPhrase = search
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
        }
        else{
            let params = '?'
            if(search !== ""){
                params += "searchPhrase="
                params += search
            }
            if(params.length > 1){
                dispatch(update_products_action([], 0, false))
                let store_params = {...searchParamsToExecute}
                store_params.page = 1
                store_params.selected = "0"
                store_params.minPrice = ''
                store_params.maxPrice = ''
                store_params.minPoints = ''
                store_params.maxPoints = ''
                store_params.searchPhrase = search
                dispatch(update_search_params_action(store_params))
                history.push(`/${params}`)
            }
        }
    };

    const categoryOnClick = (Id) => {
        if(homePage){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.minPrice = ''
            params.maxPrice = ''
            params.searchPhrase = ''
            params.selected = Id
            params.onBackPressed = false
            setSearchField('')
            dispatch(update_search_params_action(params))
            if(size < 640){
                handleCloseFilter()
            }
        }
        else{
            dispatch(update_products_action([], 0, false))
            let store_params = {...searchParamsToExecute}
            store_params.page = 1
            store_params.selected = Id
            store_params.minPrice = ''
            store_params.maxPrice = ''
            store_params.selectedCurrency = 'TODAS'
            store_params.searchPhrase = ''
            dispatch(update_search_params_action(store_params))
            history.push(`/?categoryId=${Id}`)
        }
    }
    // #endregion
    
    // #region HandleChange
    const handleChange = (event) => {
        setSearchField(event.target.value);
        if(homePage){
            handleSearchClick(event.target.value)
        }
    };
    const handleChangeMinPoints = (event) => {
        if(minPointsError){
            setMinPointsError("")
            handleMinPointsErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPoints = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPointsError('Los puntos Mininimos deben ser un numero')
                handleMinPointsErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPoints = (event) => {
        if(maxPointsError){
            setMaxPointsError("")
            handleMaxPointsErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPoints = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPointsError('Los puntos Maximos deben ser un numero')
                handleMaxPointsErrorTooltipOpen()
            }
        }
    };
    const handleChangeMinPrice = (event) => {
        if(minPriceError){
            setMinPriceError("")
            handleMinPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPriceError('El Precio debe ser un numero')
                handleMinPriceErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPrice = (event) => {
        if(maxPriceError){
            setMaxPriceError("")
            handleMaxPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPriceError('El Precio debe ser un numero')
                handleMaxPriceErrorTooltipOpen()
            }
        }
    };
    // #endregion

    // #region OTHERS
    const handleCloseCategories = (Id) => {
        setAnchorElCategories(null);
        if (Id !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = Id
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
        }
    };
    const handleCloseDrawer = (name) => {
        setAnchorElDrawer(null);
        if (name !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selectedProvince = name
            params.onBackPressed = false
            dispatch(update_search_params_action(params))  
        }
    };
    const handleCloseUserOptions = () => {
        setAnchorElUserOptions(null);
    };
    const handleCloseFilter = () => {
        //!!!PROBAR PUTO MENU
        // return
        handleMinPriceErrorTooltipClose()
        handleMinPriceErrorTooltipClose()
        setAnchorElFilter(null);
    };

    const handleMinPriceErrorTooltipClose = () => {
        setMinPriceErrorOpen(false);
    };
    const handleMaxPriceErrorTooltipClose = () => {
        setMaxPriceErrorOpen(false);
    };
    const handleMinPriceErrorTooltipOpen = () => {
        setMinPriceErrorOpen(true);
    };
    const handleMaxPriceErrorTooltipOpen = () => {
        setMaxPriceErrorOpen(true);
    };

    const handleMinPointsErrorTooltipClose = () => {
        setMinPointsErrorOpen(false);
    };
    const handleMaxPointsErrorTooltipClose = () => {
        setMaxPointsErrorOpen(false);
    };
    const handleMinPointsErrorTooltipOpen = () => {
        setMinPointsErrorOpen(true);
    };
    const handleMaxPointsErrorTooltipOpen = () => {
        setMaxPointsErrorOpen(true);
    };

      
    // #endregion

    // #region Drawer
    const toggleDrawer = (open, side) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setCategoryDrawer({...categoryDrawer, [side]: open});
      };
    
      const categoryDrawerList = () => (
        <div className={classes.categoryDrawerStyle}
          role="presentation"
          onKeyDown={toggleDrawer(false, 'left')}
          onClick={toggleDrawer(false, 'left')}
        >
        <span className={classes.drawerTextStyle}>Categorías</span>
        {categoriesLoading === true?
            <List style={{paddingTop: "0px"}}>
                {skeleton.map((skl) => {
                    return(
                    <ListItem style={{paddingTop: '8px', paddingBottom: '8px'}} button alignItems="center" key={skl}>
                        <Skeleton style={{height: '11px', width: '150px'}} />
                    </ListItem>
                    )      
                })}
            </List>
        :
            <List style={{paddingTop: "0px"}}>
                {categories.map((category) => (
                <React.Fragment>
                <ListItem style={{paddingTop: '4px', paddingBottom: '4px'}} button alignItems="center" selected={searchParamsToExecute.selected === category._id} key={category._id} onClick={() => {categoryOnClick(category._id)}} className={(category._id === searchParamsToExecute.selected)? classes.categoryActive : ""}>
                    
                    <Typography style={{color: searchParamsToExecute.selected === category._id? 'white' : colors.secondary, fontSize: '16px'}}>
                        {category.name}
                    </Typography>
                </ListItem>
                <Divider></Divider>
                </React.Fragment>
                ))}
            </List>
        }
        </div>
      );

      const categoryMainDrawerList = () => (
        <div
          role="presentation"
          onKeyDown={toggleDrawer(false, 'left')}
          onClick={toggleDrawer(false, 'left')}
        >
          <List style={{paddingTop: "10px", paddingBottom: '10px', width: '100%'}}>
            {categoriesLoading === true?
                [skeleton.map((skl) => {
                    return(
                    <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" key={skl}>
                        <Skeleton style={{height: '20px', width: '150px'}} />
                    </ListItem>
                    )      
                })]
            :
                [categories.map((category) => (
                <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" selected={searchParamsToExecute.selected === category._id} key={category._id} onClick={() => {categoryOnClick(category._id)}} className={(category._id === searchParamsToExecute.selected)? classes.categoryActive : ""}>
                    
                    <Typography className={classes.categoryElement} style={{color: searchParamsToExecute.selected === category._id? 'white' : colors.secondary}}>
                        {category.name.toUpperCase()}
                    </Typography>
                </ListItem>
                ))]
            }
          </List>
        </div>
      );
    
    //   const optionsList = () => (
    //     <div
    //       className={classes.categoryDrawerStyle}
    //       role="presentation"
    //       onClick={toggleDrawer(false, 'right')}
    //       onKeyDown={toggleDrawer(false, 'right')}
    //     >
    //       <span className={classes.drawerTextStyle}>Opciones</span>
    //       <List style={{paddingTop: "0px"}}>
    //         <Divider ></Divider>
    //         {accessToken?
    //         <React.Fragment>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={0} onClick={toAccountPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><AccountCircleIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText style={{color: colors.secondary}} primary={"MI CUENTA"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={1} onClick={toCartPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}>
                        
    //                     <StyledBadge color="error" badgeContent={cart_store_selector.count}>
    //                         <ShoppingCartIcon style={{color:colors.secondary}}/>
    //                     </StyledBadge>
                        
    //                 </ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"MI CARRITO"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             {(userLogged?.isImportCompany) &&
    //             <React.Fragment>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={2} onClick={toDashBoardPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><MonetizationOn className={classes.sellIconOptions}/></ListItemIcon>
    //                 <ListItemText style={{color: colors.secondary}} primary={"Vende"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={3} onClick={toMyProductsPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><ListIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"MIS ANUNCIOS"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             {userLogged?.isImportCompany &&
    //             <React.Fragment>
    //                 <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={4} onClick={toMyDeliversPage}>
    //                     <ListItemIcon style={{minWidth: '40px'}}><LocalShipping style={{color:colors.secondary}}/></ListItemIcon>
    //                     <ListItemText 
    //                         style={{color: colors.secondary}} primary={"MIS ENTREGAS"} />
    //                 </ListItem>
    //                 <Divider></Divider>
    //             </React.Fragment>
    //             }
    //             </React.Fragment>
    //             }
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={5} onClick={toCartPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}>
                        
    //                     <StyledBadge
    //                      color="error" badgeContent={cart_store_selector.count}>
    //                         <ShoppingCartIcon style={{color:colors.secondary}}/>
    //                     </StyledBadge>
                        
    //                 </ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"Mi Carrito"} />
    //             </ListItem>
    //             <Divider></Divider>
                
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={6} onClick={toOrdersPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><Receipt style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={userLogged?.isImportAdmin? 'ADMINISTRAR PEDIDOS' : 'MIS PEDIDOS'} />
    //             </ListItem>
    //             <Divider></Divider>
                   
    //             {userLogged?.isSuperAdmin &&
    //                 <React.Fragment>
    //                     <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={7} onClick={toAdminUsersPage}>
    //                         <ListItemIcon style={{minWidth: '40px'}}><Group style={{color:colors.secondary}}/></ListItemIcon>
    //                         <ListItemText 
    //                             style={{color: colors.secondary}} primary={'ADMINISTRAR USUARIOS'} />
    //                     </ListItem>
    //                     <Divider></Divider>
    //                 </React.Fragment>
    //             }
    //             {userLogged?.isSuperAdmin &&
    //                 <React.Fragment>
    //                     <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={8} onClick={toAdminProductsPage}>
    //                         <ListItemIcon style={{minWidth: '40px'}}><Inbox style={{color:colors.secondary}}/></ListItemIcon>
    //                         <ListItemText 
    //                             style={{color: colors.secondary}} primary={'ADMINISTRAR PRODUCTOS'} />
    //                     </ListItem>
    //                     <Divider></Divider>
    //                 </React.Fragment>
    //             }
    //             {userLogged?.isSuperAdmin &&
    //                 <React.Fragment>
    //                     <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={9} onClick={toAdminCategoriesPage}>
    //                         <ListItemIcon style={{minWidth: '40px'}}><Apps style={{color:colors.secondary}}/></ListItemIcon>
    //                         <ListItemText 
    //                             style={{color: colors.secondary}} primary={'ADMINISTRAR CATEGORIAS'} />
    //                     </ListItem>
    //                     <Divider></Divider>
    //                 </React.Fragment>
    //             }
    //             {userLogged?.isSuperAdmin &&
    //                 <React.Fragment>
    //                     <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={10} onClick={toAdminProvidersPage}>
    //                         <ListItemIcon style={{minWidth: '40px'}}><Store style={{color:colors.secondary}}/></ListItemIcon>
    //                         <ListItemText 
    //                             style={{color: colors.secondary}} primary={'ADMINISTRAR SUMINISTRADORES'} />
    //                     </ListItem>
    //                     <Divider></Divider>
    //                 </React.Fragment>
    //             }
    //             {(userLogged?.isSuperAdmin) &&
    //                 <React.Fragment>
    //                     <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={11} onClick={toFinancePage}>
    //                         <ListItemIcon style={{minWidth: '40px'}}><AccountBalanceWallet style={{color:colors.secondary}}/></ListItemIcon>
    //                         <ListItemText 
    //                             style={{color: colors.secondary}} primary={'FINANZAS'} />
    //                     </ListItem>
    //                     <Divider></Divider>
    //                 </React.Fragment>
    //             }
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={12} onClick={toFavoritesPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><FavoriteIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"FAVORITOS"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={13} onClick={toLegalInformationPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><InfoIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"INFORMACION LEGAL"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={14}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><SendIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"CONTACTAR Y AYUDA"} />
    //             </ListItem>
    //             </a>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={15} onClick={toHelpPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><HelpIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"PREGUNTAS FRECUENTES"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <a target="_blank" href={'https://www.gacetaoficial.gob.cu/sites/default/files/goc-2022-ex45_0.pdf'} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={16}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><Assignment style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"GACETA OFICIAL ADUANA"} />
    //             </ListItem>
    //             </a>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={17} onClick={userLoggedOut}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><CloseIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"CERRAR SESION"} />
    //             </ListItem>
    //         </React.Fragment>
    //         :
    //         <React.Fragment>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={1} onClick={handleClickOpen}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><AccountCircleIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"iniciar Sesión"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={9} onClick={toLegalInformationPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><InfoIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"INFORMACION LEGAL"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={10}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><SendIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"CONTACTAR Y AYUDA"} />
    //             </ListItem>
    //             </a>
    //             <Divider></Divider>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={11} onClick={toHelpPage}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><HelpIcon style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"PREGUNTAS FRECUENTES"} />
    //             </ListItem>
    //             <Divider></Divider>
    //             <a target="_blank" href={'https://www.gacetaoficial.gob.cu/sites/default/files/goc-2022-ex45_0.pdf'} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
    //             <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}}  button key={10}>
    //                 <ListItemIcon style={{minWidth: '40px'}}><Assignment style={{color:colors.secondary}}/></ListItemIcon>
    //                 <ListItemText 
    //                     style={{color: colors.secondary}} primary={"GACETA OFICIAL ADUANA"} />
    //             </ListItem>
    //             </a>
    //         </React.Fragment>
    //         }
    //         <Divider></Divider>
    //       </List>
    //     </div>
    //   );
    // #endregion

    const handleChangeVisibility = async isVisible => {
        console.log("VISIBLE: ", isVisible)
        // const isSession = window.localStorage.getItem('session')
        // let sessionId = null
        // if(isSession){
        //     sessionId = JSON.parse(isSession)._id
        // }
        // else{
        //     const newSessionId = uuidv4()
        //     window.localStorage.setItem(
        //     'session', JSON.stringify({_id: newSessionId})
        //     )
        //     sessionId = newSessionId
        // }
        // if(userLogged){
        //     if(sessionId){
        //         if(socket_store_selector.socket.current){
        //             socket_store_selector.socket.emit("visibilityImport", {isVisible: isVisible, userId: userLogged._id, deviceId: sessionId})
        //         }
                
        //     }
        //     const requestOptions = {
        //         method: 'GET',
        //         headers: { 'Content-Type': 'application/json',
        //                     'Authorization': `${userLogged.accessToken}`}
        //         };
        //     let result = await fetch(`${url}/api/conversations/unreadMessages`, requestOptions);
        //     if(result.status === 200){
        //         let newData = await result.json();
        //         if(newData.unreadMessages){
        //             dispatch(unread_messages_action())
        //         }
        //     }
        // }
    }

    const toHomePage = () => {
        if(homePage){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = "0"
            params.minPrice = ''
            params.maxPrice = ''
            params.selectedCurrency = 'TODAS'
            params.searchPhrase = ''
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
            setSearchField("")
            if(size < 640){
                handleCloseFilter()
            }
        }
        else{
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = "0"
            params.minPrice = ''
            params.maxPrice  = ''
            params.selectedCurrency  = 'TODAS'
            params.searchPhrase = ''
            dispatch(update_search_params_action(params))
            history.push('/')
        }
    }

    const onCloseSelectRegionModal = () => {
        setOpenSelectRegionModal(false)
    }

    return (
        <div>
            {/*app bar*/}
            <CssBaseline />
            <PageVisibility  onChange={handleChangeVisibility}/>
            <Snackbar open={snackUserLoginProps.open} autoHideDuration={snackUserLoginProps.closingTime} onClose={handleCloseUserLoginPropsSnack}>
                <Alert onClose={handleCloseUserLoginPropsSnack} severity={snackUserLoginProps.severity}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>{snackUserLoginProps.text}</Typography> 
                        {snackUserLoginProps.circularProgress && 
                            <CircularProgress style={{height: '15px', width: '15px', color: colors.whiteAppbar, marginLeft: '5px'}}/>
                        }
                    </div>
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccesSnack} autoHideDuration={1000} onClose={handleCloseSuccesSnack}>
                <Alert onClose={handleCloseSuccesSnack} severity="success">
                    Se inició sesión correctamente.
                </Alert>
            </Snackbar>
            <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                <Alert onClose={handleCloseApiErrorSnack} severity="error">
                    No se pudo iniciar sesión. Revise su conexión a Internet.
                </Alert>
            </Snackbar>
            <Snackbar open={openRegisterSuccesSnack} autoHideDuration={1000} onClose={handleCloseRegisterSuccesSnack}>
                <Alert onClose={handleCloseRegisterSuccesSnack} severity="success">
                    Usuario registrado correctamente. Iniciando sesión...
                </Alert>
            </Snackbar>
            <Snackbar open={openRegisterApiErrorSnack} autoHideDuration={2000} onClose={handleCloseRegisterApiErrorSnack}>
                <Alert onClose={handleCloseRegisterApiErrorSnack} severity="error">
                    No se pudo registrar el usuario. Revise su conexión a Internet.
                </Alert>
            </Snackbar>
            <SelectRegionModal open={openSelectRegionModal} onClose={onCloseSelectRegionModal}/>
            {(userLogged?.isImportCompany) &&
            <Fab
                onClick={toDashBoardPage}
                variant="contained"
                className={classes.sellButtonFab}
                classes={{
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                >
                    {/* <AttachMoneyIcon className={classes.sellIconFab} /> */}
                    <spam style={{paddingLeft: '10px', paddingTop: '20px'}}>{'Vende'}</spam>
            </Fab>
            }
            {(!userLogged?.isImportCompany) &&
            <Fab
                onClick={toHelpWhatsap}
                variant="contained"
                className={classes.sellButtonFab}
                classes={{
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                >
                    <img src={whatsap_icon} style={{marginLeft: '29px', marginBottom: '-3px', height: '40px', width: 'auto'}}/>
                    <spam style={{marginBottom: '-10px'}}>AYUDA</spam>
            </Fab>
            }
            <ElevationScroll {...props}>
                <AppBar className={classes.appBar}
                >
                    <HideOnScroll  {...props}>
                    {userLogged?.email === "thygardener@gmail.com" &&
                    <Toolbar className={classes.mainToolbar}>

                    </Toolbar>
                    }

                    <Toolbar
                        className={classes.mainToolbar}>
                        {/* <Button 
                            className={classes.categoriesButton}
                            onClick={toggleDrawer(true, 'left')}
                        >
                            <BsGridFill className={classes.categoriesIcon}/>
                        </Button>
                        <SwipeableDrawer
                            anchor={'left'}
                            open={categoryDrawer['left']}
                            onClose={toggleDrawer(false, 'left')}
                            onOpen={toggleDrawer(true, 'left')}
                        >
                            {categoryDrawerList()}
                        </SwipeableDrawer> */}
                        <div className={classes.extraPaddingDiv} />
                        <div className={classes.appBarContainer}>
                            <div className={classes.logoHolder}>
                                
                                <img alt="" src={(size < 640 && searchField)? logoSmall : logo} className={classes.logo}
                                    onClick={toHomePage}
                                />
                                <img alt="" src={logoSmall} className={classes.logoSmall}
                                    onClick={toHomePage}
                                />
                            </div>
                            <div
                                onClick={handleClickFilter}
                                onMouseEnter={handleClickFilter} 
                                className={classes.SearchAndFilter}>
                                
                                <HomeCustomIcon onClick={toHomePage} className={`${homePage? classes.homeIcon+' '+classes.selectedCustomIcon : classes.homeIcon}`}/>
                                <div className={classes.search}>
                                    <InputBase
                                        readOnly
                                        elevation={4}
                                        placeholder={size <= 768? ( size <= 640? "BUSCA" : "BUSCA | COMPRA") : "BUSCA | COMPRA | IMPORTA"}
                                        value={searchField}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                                <div 
                                    className={classes.searchIcon}>
                                    <SearchIcon style={{fontSize: '1.3rem'}}/>
                                </div>
                                
                            </div>
                            <StyledMenu
                                id="filter-menu"
                                anchorEl={anchorElFilter}
                                keepMounted
                                open={openFilter}
                                PaperProps={{
                                    className: classes.searchMenu
                                }}
                                onClose={handleCloseFilter}
                            >
                                
                                <div style={{display: 'flex', flexDirection: 'column'}} onMouseLeave={handleCloseFilter}>
                                    <div className={classes.searchMenuHolder}>
                                        <div className={classes.closeSearchMenuSmall}>
                                            <div 
                                                style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                                onClick={() => {setOpenSelectRegionModal(true); handleCloseFilter()}}
                                            >
                                                <LocationOn style={{color: colors.whiteAppbar, width: '24px', height: '24px'}}/>
                                                <Typography className={classes.filterTextSmall}>{searchParamsToExecute.selectedProvince.toUpperCase()}</Typography>
                                                <ArrowDropDown style={{color: colors.whiteAppbar, width: '22px', height: '22px'}}/>
                                            </div>
                                            <IconButton onClick={handleCloseFilter} className={classes.closeSearchMenuButton}>
                                                <Close />
                                            </IconButton>
                                        </div>
                                        <div className={classes.SearchAndFilterInside}>
                                            
                                            <HomeCustomIcon onClick={toHomePage} className={`${homePage? classes.homeIcon+' '+classes.selectedCustomIcon : classes.homeIcon}`}/>
                                            
                                            <div className={classes.search}>
                                                <InputBase
                                                    elevation={4}
                                                    placeholder={size <= 768? ( size <= 640? "BUSCA" : "BUSCA | COMPRA") : "BUSCA | COMPRA | IMPORTA"}
                                                    value={searchField}
                                                    onKeyDown={handleKeyDown}
                                                    onChange={handleChange}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput
                                                    }}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                            </div>
                                            <div
                                                onClick = {() => {
                                                    if(homePage){
                                                        handleCloseFilter()
                                                    }
                                                    else{
                                                        handleSearchClick()
                                                    }
                                                }} 
                                                className={`${classes.searchIcon} ${classes.selectedIcon}`}>
                                                <SearchIcon style={{fontSize: '1.3rem'}}/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className={classes.categoriesAndPriceFilter}>
                                        {/* <div style={{height: '10px', backgroundColor: colors.primary, width: '100%'}} /> */}
                                        <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                            <div className={classes.categoriesHolder}>
                                                {categoryMainDrawerList()}
                                            </div>   
                                            <div style={{flex: 1}} className={classes.hideSmall} >
                                                <img src={search_menu_image} style={{width: '100%', maxHeight: 'calc(100vh - 200px)', objectFit: 'cover'}}/>
                                            </div> 
                                        </div>
                                        <div className={classes.filterPopup}>
                                            <div className={classes.filterPriceAndPoints}>
                                                
                                                <div style={{display:'flex', alignItems: 'center', marginTop: '10px', marginBottom: '5px', marginRight: 'auto', marginLeft: 'auto'}}>
                                                    <div 
                                                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                                                        onClick={() => {setOpenSelectRegionModal(true)}}
                                                    >
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <LocationOn style={{color: colors.whiteAppbar, width: '18px', height: '18px'}}/>
                                                            <Typography className={classes.filterText}>PROVINCIA DE ENTREGA</Typography>
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Typography className={classes.filterText}>{searchParamsToExecute.selectedProvince.toUpperCase()}</Typography>
                                                            <ArrowDropDown style={{color: colors.whiteAppbar, width: '18px', height: '18px'}}/>
                                                        </div>
                                                    </div>
                                                    {/* <Typography className={classes.filterText}>WWW.SEVENDEIMPORT.COM</Typography> */}
                                                    {/* <AttachMoney className={classes.filterIcon}/>
                                                    <Typography className={classes.filterText}>PRECIO</Typography>
                                                    <div className={classes.inputHolder}>
                                                        <InputBase
                                                            elevation={4}
                                                            onClick={() => 
                                                                {
                                                                    if(minPriceError) handleMinPriceErrorTooltipOpen()
                                                                    if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                                                    if(minPointsError) handleMinPointsErrorTooltipClose()
                                                                    if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                                                }}
                                                            type="text"
                                                            value={searchParamsToExecute.minPrice}
                                                            placeholder="MIN"
                                                            onChange={handleChangeMinPrice}
                                                            classes={{
                                                                root: classes.inputRootLogin,
                                                                input: classes.inputInputLogin,
                                                            }}
                                                            inputProps={{ 'aria-label': 'search' }}
                                                        />
                                                        {minPriceError &&
                                                            <div>
                                                            <MuiThemeProvider theme={themeMUI}>
                                                                <Tooltip classes={{arrow: classes.arrow}}
                                                                    arrow
                                                                    PopperProps={{
                                                                    disablePortal: true,
                                                                    }}
                                                                    onClose={handleMinPriceErrorTooltipClose}
                                                                    open={minPriceErrorOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={minPriceError}
                                                                >
                                                                    <IconButton onClick={() => {
                                                                                    if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                                                    if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                                                    if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                                                    handleMinPriceErrorTooltipOpen()
                                                                                }} 
                                                                                aria-label="warning" className={classes.errorButton}>
                                                                        <WarningAmberIcon fontSize="small" />
                                                                    </IconButton>
                                                                    
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                            </div>                       
                                                        }
                                                    </div>
                                                    <div className={classes.inputHolder}>
                                                        <InputBase
                                                            elevation={4}
                                                            onClick={() => 
                                                                {
                                                                    if(minPriceError) handleMinPriceErrorTooltipClose()
                                                                    if(maxPriceError) handleMaxPriceErrorTooltipOpen()
                                                                    if(minPointsError) handleMinPointsErrorTooltipClose()
                                                                    if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                                                }}
                                                            type="text"
                                                            value={searchParamsToExecute.maxPrice}
                                                            onChange={handleChangeMaxPrice}
                                                            placeholder="MAX"
                                                            classes={{
                                                                root: classes.inputRootLogin,
                                                                input: classes.inputInputLogin,
                                                            }}
                                                            inputProps={{ 'aria-label': 'search' }}
                                                        />
                                                        {maxPriceError &&
                                                            <div>
                                                            <MuiThemeProvider theme={themeMUI}>
                                                                <Tooltip classes={{arrow: classes.arrow}}
                                                                    arrow
                                                                    PopperProps={{
                                                                    disablePortal: true,
                                                                    }}
                                                                    onClose={handleMaxPriceErrorTooltipClose}
                                                                    open={maxPriceErrorOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={maxPriceError}
                                                                >
                                                                    <IconButton onClick={() => {
                                                                                    if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                                                    if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                                                    if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                                                    handleMaxPriceErrorTooltipOpen()
                                                                                }}  aria-label="warning" className={classes.errorButton}>
                                                                        <WarningAmberIcon fontSize="small" />
                                                                    </IconButton>
                                                                    
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                            </div>                       
                                                        }
                                                    </div> */}
                                                </div>
                                                {/* <div style={{display:'flex', alignItems: 'center', marginTop: '10px', marginBottom: '5px', marginRight: 'auto'}}>
                                                    <Score className={classes.filterIcon}/>
                                                    <Typography className={classes.filterText}>PUNTOS</Typography>
                                                    <div className={classes.inputHolder}>
                                                        <InputBase
                                                            elevation={4}
                                                            onClick={() => 
                                                                {
                                                                    if(minPriceError) handleMinPriceErrorTooltipClose()
                                                                    if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                                                    if(minPointsError) handleMinPointsErrorTooltipOpen()
                                                                    if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                                                }}
                                                            type="text"
                                                            value={searchParamsToExecute.minPoints}
                                                            placeholder="MIN"
                                                            onChange={handleChangeMinPoints}
                                                            classes={{
                                                                root: classes.inputRootLogin,
                                                                input: classes.inputInputLogin,
                                                            }}
                                                            inputProps={{ 'aria-label': 'search' }}
                                                        />
                                                        {minPointsError &&
                                                            <div>
                                                            <MuiThemeProvider theme={themeMUI}>
                                                                <Tooltip classes={{arrow: classes.arrow}}
                                                                    arrow
                                                                    PopperProps={{
                                                                    disablePortal: true,
                                                                    }}
                                                                    onClose={handleMinPointsErrorTooltipClose}
                                                                    open={minPointsErrorOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={minPointsError}
                                                                >
                                                                    <IconButton onClick={() => {
                                                                                    if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                                                    if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                                                    if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                                                    handleMinPointsErrorTooltipOpen()
                                                                                }} 
                                                                                aria-label="warning" className={classes.errorButton}>
                                                                        <WarningAmberIcon fontSize="small" />
                                                                    </IconButton>
                                                                    
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                            </div>                       
                                                        }
                                                    </div>
                                                    <div className={classes.inputHolder}>
                                                        <InputBase
                                                            elevation={4}
                                                            onClick={() => 
                                                                {
                                                                    if(minPriceError) handleMinPriceErrorTooltipClose()
                                                                    if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                                                    if(minPointsError) handleMinPointsErrorTooltipClose()
                                                                    if(maxPointsError) handleMaxPointsErrorTooltipOpen()
                                                                }}
                                                            type="text"
                                                            value={searchParamsToExecute.maxPoints}
                                                            onChange={handleChangeMaxPoints}
                                                            placeholder="MAX"
                                                            classes={{
                                                                root: classes.inputRootLogin,
                                                                input: classes.inputInputLogin,
                                                            }}
                                                            inputProps={{ 'aria-label': 'search' }}
                                                        />
                                                        {maxPointsError &&
                                                            <div>
                                                            <MuiThemeProvider theme={themeMUI}>
                                                                <Tooltip classes={{arrow: classes.arrow}}
                                                                    arrow
                                                                    PopperProps={{
                                                                    disablePortal: true,
                                                                    }}
                                                                    onClose={handleMaxPointsErrorTooltipClose}
                                                                    open={maxPointsErrorOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={maxPointsError}
                                                                >
                                                                    <IconButton onClick={() => {
                                                                                   if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                                                   if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                                                   if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                                                   handleMaxPointsErrorTooltipOpen()
                                                                                }}  aria-label="warning" className={classes.errorButton}>
                                                                        <WarningAmberIcon fontSize="small" />
                                                                    </IconButton>
                                                                    
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                            </div>                       
                                                        }
                                                    </div>
                                                </div>             */}
                                            </div>
                                            {/* <div className={classes.discountAndFastDelivery}>
                                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '5px', height: '23px', cursor: 'pointer'}}>
                                                    <LocalOffer className={classes.filterIcon} />
                                                    <Typography className={classes.filterText}>POSIBLE DESCUENTO</Typography>
                                                    <div style={{marginLeft: '3px', height: '15px', width: '15px', backgroundColor: colors.secondary, border: 'solid 2.5px white', borderRadius: '2px'}}/>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '5px', height: '23px', cursor: 'pointer'}}>
                                                    <LocalShipping className={classes.filterIcon} />
                                                    <Typography className={classes.filterText}>ENTREGA INMEDIATA</Typography>
                                                    <div style={{marginLeft: '22px', height: '15px', width: '15px', backgroundColor: colors.secondary, border: 'solid 2.5px white', borderRadius: '2px'}}/>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </StyledMenu>
                            <StyledMenu
                                id="long-menu-user"
                                anchorEl={anchorElUserOptions}
                                keepMounted
                                open={openUserOptions}
                                onClose={() => handleCloseUserOptions(null)}
                                PaperProps={{
                                style: {
                                },
                                }}
                            >    
                                {accessToken? 
                                <MenuItem style={{fontSize: '14px'}} key={0} onClick={() => {handleCloseUserOptions()
                                                                toAccountPage()}}
                                >
                                    <AccountCircleIcon className={classes.menuIconUserOptions}/> MI CUENTA
                                </MenuItem>
                                :
                                <MenuItem style={{fontSize: '14px'}} key={0} onClick={() => {handleCloseUserOptions()
                                    handleClickOpen()}}
                                    >
                                    <AccountCircleIcon className={classes.menuIconUserOptions}/> INICIAR SESION
                                </MenuItem>
                                }
                                {accessToken &&
                                <MenuItem style={{fontSize: '14px'}} key={18} onClick={() => {handleCloseUserOptions()
                                    toCartPage()}}
                                    >
                                        <ShoppingCartIcon className={classes.menuIconUserOptions} /> MI CARRITO
                                    </MenuItem>
                                }
                                {(userLogged?.isImportCompany) &&
                                <MenuItem style={{fontSize: '14px'}} key={1} onClick={() => {handleCloseUserOptions()
                                                                toMyProductsPage()}}
                                >
                                    <ListIcon className={classes.menuIconUserOptions} /> MIS ANUNCIOS
                                </MenuItem>
                                }
                                {userLogged?.isImportCompany &&
                                <MenuItem style={{fontSize: '14px'}} key={12} onClick={() => {handleCloseUserOptions()
                                    toMyDeliversPage()}}
                                    >
                                        <LocalShipping className={classes.menuIconUserOptions} /> MIS ENTREGAS
                                    </MenuItem>
                                }
                                {accessToken &&
                                <MenuItem style={{fontSize: '14px'}} key={3} onClick={() => {handleCloseUserOptions()
                                                                toOrdersPage()}}
                                >
                                    <Receipt className={classes.menuIconUserOptions} /> {userLogged?.isImportAdmin? 'ADMINISTRAR PEDIDOS' : 'MIS PEDIDOS'}
                                </MenuItem>
                                }
                                
                                {userLogged?.isSuperAdmin &&
                                <MenuItem style={{fontSize: '14px'}} key={4} onClick={() => {handleCloseUserOptions()
                                                                toAdminUsersPage()}}
                                >
                                    <Group className={classes.menuIconUserOptions} /> {'ADMINISTRAR USUARIOS'}
                                </MenuItem>
                                }
                                {(userLogged?.isSuperAdmin) &&
                                <MenuItem style={{fontSize: '14px'}} key={9} onClick={() => {handleCloseUserOptions()
                                                                toAdminProductsPage()}}
                                >
                                    <Inbox className={classes.menuIconUserOptions} /> {'ADMINISTRAR PRODUCTOS'}
                                </MenuItem>
                                }
                                {(userLogged?.isSuperAdmin) &&
                                <MenuItem style={{fontSize: '14px'}} key={10} onClick={() => {handleCloseUserOptions()
                                                                toAdminCategoriesPage()}}
                                >
                                    <Apps className={classes.menuIconUserOptions} /> {'ADMININSTRAR CATEGORIAS'}
                                </MenuItem>
                                }
                                {(userLogged?.isSuperAdmin) &&
                                <MenuItem style={{fontSize: '14px'}} key={11} onClick={() => {handleCloseUserOptions()
                                                                toAdminProvidersPage()}}
                                >
                                    <Store className={classes.menuIconUserOptions} /> {'ADMINISTRAR SUMINISTRADORES'}
                                </MenuItem>
                                }
                                {(userLogged?.isSuperAdmin) &&
                                <MenuItem style={{fontSize: '14px'}} key={5} onClick={() => {handleCloseUserOptions()
                                                                toFinancePage()}}
                                >
                                    <AccountBalanceWallet className={classes.menuIconUserOptions} /> {'FINANZAS'}
                                </MenuItem>
                                }
                                {(userLogged?.isSuperAdmin) &&
                                <MenuItem style={{fontSize: '14px'}} key={5} onClick={() => {handleCloseUserOptions()
                                                                toCurrencyExchangePage()}}
                                >
                                    <EmojiSymbols className={classes.menuIconUserOptions} /> {'CAMBIO DE MONEDAS'}
                                </MenuItem>
                                }
                                <MenuItem style={{fontSize: '14px'}} key={6} onClick={() => {handleCloseUserOptions()
                                                                toLegalInformationPage()}}
                                >
                                    <InfoIcon className={classes.menuIconUserOptions} /> {'INFORMACION LEGAL'}
                                </MenuItem>
                                <MenuItem style={{fontSize: '14px'}} key={7} onClick={() => {handleCloseUserOptions()}}
                                >
                                    <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{display: 'flex', alignItems: 'center', marginTop: '3px', cursor: 'pointer', textDecoration: 'none', color: 'black', opacity: '0.9', width: '100%'}}><SendIcon className={classes.menuIconUserOptions} /> CONTACTAR Y AYUDA</a>
                                </MenuItem>
                                <MenuItem style={{fontSize: '14px'}} key={8} onClick={() => {handleCloseUserOptions()
                                                                toHelpPage()}}
                                >
                                    <HelpIcon className={classes.menuIconUserOptions} /> {'PREGUNTAS FRECUENTES'}
                                </MenuItem>
                                <MenuItem style={{fontSize: '14px'}} key={9} onClick={() => {handleCloseUserOptions()}}
                                >
                                    <a target="_blank" href={'https://www.gacetaoficial.gob.cu/sites/default/files/goc-2022-ex45_0.pdf'} style={{display: 'flex', alignItems: 'center', marginTop: '3px', cursor: 'pointer', textDecoration: 'none', color: 'black', opacity: '0.9', width: '100%'}}><Assignment className={classes.menuIconUserOptions} /> GACETA OFICIAL ADUANA</a>
                                </MenuItem>
                                {accessToken &&
                                <MenuItem style={{fontSize: '14px'}} key={2} onClick={() => {handleCloseUserOptions()
                                                                userLoggedOut()}}
                                >
                                    <CloseIcon className={classes.menuIconUserOptions} /> CERRAR SESION
                                </MenuItem>   
                                } 
                            </StyledMenu>
                            <div className={classes.optionButtonsHolder}>
                                {accessToken?
                                <React.Fragment>
                                    
                                    {userLogged?.isImportCompany ?
                                    <Button onClick={toDashBoardPage} disabled={!userLogged?.isImportCompany} className={classes.sellButton} style={{width: '64px', color: sellPage? colors.primary : 'inherit'}}>
                                        VENDE
                                    </Button>
                                    :
                                    <div style={{width: '64px'}} />
                                    }
                                    <IconButton
                                            onClick={toCartPage}
                                            className={classes.optionButton}
                                            style={{padding:'9px'}}
                                            edge="end"
                                            aria-label="messages">
                                        {cartPage?
                                        <StyledBadgePrimary color="error" badgeContent={cart_store_selector.count}>
                                            <ShoppingCartIcon fontSize='small' className={`${cartPage? classes.optionIcon+' '+classes.selectedCustomIcon : classes.optionIcon}`}/>
                                        </StyledBadgePrimary>
                                        :
                                        <StyledBadge color="error" badgeContent={cart_store_selector.count}>
                                            <ShoppingCartIcon fontSize='small' className={`${cartPage? classes.optionIcon+' '+classes.selectedCustomIcon : classes.optionIcon}`}/>
                                        </StyledBadge>
                                        }
                                        
                                    </IconButton>
                                    <IconButton
                                            onClick={toFavoritesPage}
                                            className={classes.optionButton}
                                            style={{padding:'9px'}}
                                            edge="end"
                                            aria-label="messages">
                                        <FavoriteIcon fontSize='small' className={`${favoritesPage? classes.optionIcon+' '+classes.selectedCustomIcon : classes.optionIcon}`}/>
                                    </IconButton>
                                    
                                    <IconButton edge='end' 
                                        className={classes.optionButton}
                                        onClick={handleClickUserOptions}
                                        style={{padding:'8px'}}
                                    >
                                        {userLogged?.profilePictureUrl?
                                            <img src={userLogged.profilePictureUrl} className={classes.profileAvatar} />
                                        :
                                            <Person className={classes.optionIcon} />
                                        }
                                    </IconButton>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    
                                    
                                    <Button edge='end' 
                                        className={classes.loginButton}
                                        style={{padding: '6px 16px', marginLeft: '10px'}}
                                        onClick={handleClickOpen}
                                        startIcon={<Person className={classes.menuIconUserOptions} style={{marginRight: '-2px'}}/>}
                                    >
                                        INICIAR SESION
                                    </Button>
                                    </React.Fragment>
                                }
                            </div>
                            <div className={classes.optionButtonsHolderSmall}>
                                <Button 
                                    className={classes.optionsButton}
                                    // onClick={toggleDrawer(true, 'right')}
                                    onClick={handleClickUserOptions}
                                >
                                    
                                    <StyledBadge color="error" badgeContent={cart_store_selector.count}>
                                        <DehazeIcon className={classes.optionsIcon}/>
                                    </StyledBadge>
                                    
                                </Button>
                                {searchField?
                                    <div onClick={handleClickFilter} className={classes.searchSmallButton}>
                                        <Typography style={{color: colors.lightGrayLogo, paddingRight: '10px', whiteSpace: 'nowrap', maxWidth: 'calc(100vw - 200px)', minWidth: '100px', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{searchField}</Typography>
                                        <div 
                                            className={classes.searchIconSmall}>
                                            <SearchIcon style={{height: '20px', width: '20px'}}/>
                                        </div>
                                    </div>
                                :
                                    <div onClick={handleClickFilter} className={classes.searchSmallButton}>
                                        <div 
                                            className={classes.searchIconSmall}>
                                            <SearchIcon style={{height: '20px', width: '20px'}}/>
                                        </div>
                                        <Typography style={{color: colors.lightGrayLogo}}>{size < 330? '' : 'BUSCAR'}</Typography>
                                    </div>
                                }
                                
                            </div>
                        </div>
                        <div className={`${classes.extraPaddingDiv}`}/>
                        {/* <SwipeableDrawer
                            anchor={'right'}
                            open={categoryDrawer['right']}
                            onClose={toggleDrawer(false, 'right')}
                            onOpen={toggleDrawer(true, 'right')}
                        >
                            {optionsList()}
                        </SwipeableDrawer> */}
                    </Toolbar>
                    </HideOnScroll>
                </AppBar>
            </ElevationScroll>
            {/* {(homePage || favoritesPage || cartPage) && */}
            {true &&
            <AppBar position="fixed" className={classes.appBarTab}>
                <Toolbar className={classes.tabToolbar}>
                    <div onClick={toHomePage} className={classes.tabElement}>
                        <HomeCustomIcon className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', color: homePage? colors.primary : 'inheritance'}}/>
                    </div>
                    <div onClick={toHelpWhatsap} className={classes.tabElement}>
                        <LiveHelp className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto'}}/>
                    </div>
                    {userLogged?.isImportCompany &&
                        <div onClick={toDashBoardPage} className={classes.tabElement}>
                            <MonetizationOn className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', color: sellPage? colors.primary : 'inheritance', width: '35px', height: '35px'}}/>
                        </div>
                    }
                    <div onClick={toCartPage} className={classes.tabElement}>
                        {cartPage?
                        <StyledTabBadgePrimary style={{marginLeft: 'auto', marginRight: 'auto'}} badgeContent={cart_store_selector.count}>
                            <ShoppingCartIcon className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', color: cartPage? colors.primary : 'inheritance'}}/>
                        </StyledTabBadgePrimary>
                        :
                        <StyledTabBadge style={{marginLeft: 'auto', marginRight: 'auto'}} badgeContent={cart_store_selector.count}>
                            <ShoppingCartIcon className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', color: cartPage? colors.primary : 'inheritance'}}/>
                        </StyledTabBadge>
                        }
                    </div>
                    <div onClick={handleClickUserOptions} className={classes.tabElement}>
                        {userLogged?.profilePictureUrl?
                            <img src={userLogged.profilePictureUrl} className={classes.profileAvatar} style={{marginLeft: 'auto', marginRight: 'auto', width: '27px', height: '27px'}}/>
                        :
                            <Person className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', color: accountPage? colors.primary : 'inheritance'}}/>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            }
            {userLogged?.email === "thygardener@gmail.com" &&
                <div className={classes.mainToolbar} />
            }
            <LoginRegister 
                setOpenSuccesSnack = {OpenSuccessSnack}
                setCloseSuccesSnack = {handleCloseSuccesSnack}
                setOpenApiErrorSnack = {OpenApiErrorSnack}
                setOpenRegisterSuccesSnack = {OpenRegisterSuccessSnack}
                setOpenRegisterApiErrorSnack = {OpenRegisterApiErrorSnack}
                registerPromotion={registerPromotion} 
            />
            <div className={classes.mainToolbar}></div>
        </div>
    )
}

function HomeCustomIcon(props) {
    return (
      <SvgIcon viewBox='0 0 256 256' {...props}>
        <path d={iconsPath.homeIcon} />
      </SvgIcon>
    );
  }

export default Navbar