import { alpha, Box, Button, CircularProgress, Collapse, IconButton, InputBase, Menu, MenuItem, Modal, MuiThemeProvider, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, createTheme, withStyles } from "@material-ui/core/styles";
import { Alert, Skeleton } from "@material-ui/lab";
import React, {Suspense, useEffect, useLayoutEffect, useRef, useState} from "react";
import url, { colors, sendingCostValue, supportNumber, guideCut, packaging, discountOverValue } from "../../config";
import Navbar from "../global/NavbarGlobal";
import { AccountBalance, AccountBalanceWallet, AccountCircle, ArrowDropDown, ArrowForwardIos, Assignment, AssignmentInd, AssignmentLate, AttachMoney, Cancel, Clear, ContactSupport, CreditCard, DeleteForever, Email, EmojiSymbols, Flight, KeyboardArrowDown, KeyboardArrowUp, KeyboardTab, ListAlt, LocalOffer, LocalShipping, MonetizationOn, Money, MoneyOff, MoreVert, Person, PersonPin, PersonPinCircle, Phone, Score, SwapVert, Today, Visibility, VisibilityOff } from "@material-ui/icons";
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BsFillFunnelFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import DeleteOrderModal from "../global/DeleteOrderModal";
import NoConnection from "../global/NoConnection";
import b2_truck from "../../assets/promotional_banner/b2_truck.png"
import TitleWithBottomArrow from "../global/TitleWithBottomArrow";
import airTaxImage from '../../assets/img/air_tax_icon.png'
import walletImage from '../../assets/img/wallet_icon.jpg'
import userPlaceholder from '../../assets/img/userPlaceholder.png'
import sendCostIcon from '../../assets/img/send_cost_icon.png'
import reduceDecimalValue, { calcSendingCost, getDeliveryCalendarString } from "../../tools/productTools";
import { update_orders_action, update_search_order_params_action } from "../../redux/actions/ordersAction";
import { getDate, getPaymentTypeName, getProductDiscountForCoupon, getTotalOrder } from "../../tools/orderTools";
import Footer from "../global/Footer";

const PdfDocumentLoader = React.lazy(() => import('../global/PdfDocumentLoader'))

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
});

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
    list: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important'
    }
  })((props) => (
    <Menu
      elevation={0}
      transitionDuration={0}
      classes={{
        list: {paddingBottom: '0px !important'},
      }}
      disableScrollLock={true}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));


const useStyles = makeStyles((theme) => ({
    ordersHolder: {
        //backgroundColor: colors.containerGradientFirst,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '40px',
        },
    },
    filtersHolder: {
        backgroundColor: colors.whiteAppbar,
        display: 'flex',
        justifyContent: 'space-between',
        marginBotom: '10px',
        border: `solid 2.5px ${colors.primary}`
    },
    rowHolder: {
        marginTop: '10px',
        marginBotom: '10px'
    },
    skeletonHeigth: {
        color: colors.secondary,
        height: '20px',
        width: '80px'
    },
    rowStatusHolder: {
        maxWidth: '265px'
    },
    rowOrderInfoHolder: {
        backgroundColor: colors.whiteAppbar,
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '5px',
    },
    rowUserAddresseeAndProductsSummarize: {
        display: 'flex',
        width: '100%',
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    rowProductSummarizeSubInfo: {
        display: 'flex',
    },
    rowProductSummarizeAndOptions: {
        flex: 0.3,
        paddingLeft: '10px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flex: 0.4
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    rowProductSummarize: {
        flex: 1,
        paddingRight: '7px'
    },
    rowOptions: {
        paddingRight: '2px',
        paddingLeft: '2px',
    },
    rowUserAndAddresseeInfo: {
        display: 'flex',
        flex: 0.7,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flex: 0.6
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            flex: 1
        }
    },
    rowUserAndAddresseeDivider: {
        height: 'auto',
        width: '1px',
        marginBottom: '10px',
        marginTop: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: alpha(colors.secondary, 0.5)
    },
    rowUserAddresseeAndProductsSummarizeDivider: {
        height: 'auto',
        width: '1px',
        marginBottom: '10px',
        marginTop: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: alpha(colors.secondary, 0.5)
    },
    rowUserInfo: {
        flex: 1,
        paddingLeft: '10px'
    },
    deliveryDirectionXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: '15px',
            marginBotom: '25px'
        }
    },
    rowAddresseeInfo: {
        flex: 1,
        paddingLeft: '10px'
    },
    rowAddresseeInfoHideXs: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    rowUserSubInfo: {
        display: 'flex'
    },
    rowAddresseeSubInfo: {
        display: 'flex'
    },
    rowInfoIcon: {
        width: '16px',
        marginRight: '2px',
        opacity: '0.8'
    },
    rowInfoIconMain: {
        color: colors.primary,
        width: '20px',
        marginLeft: '2px',
        opacity: '0.9'
    },
    rowInfoIconSecondary: {
        width: '14px',
        marginRight: '2px',
        opacity: '0.8'
    },
    rowInfoText: {
        fontSize: '14px',
        fontWeight: '600'
    },
    rowInfoTextMain: {
        color: colors.primary,
        fontSize: '15px',
        fontWeight: '700'
    },
    rowInfoTextSecondary: {
        fontSize: '12px',
        fontWeight: '600'
    },
    statusText: {
        fontSize: '13px',
        fontWeight: '700',
        color: colors.whiteAppbar,
        padding: '4px 0px 2px 0px',
        textAlign: 'center'
    },
    orderInfoHolder: {
        //textAlign: 'center',
        marginTop: '10px',
        //backgroundColor: colors.containerGradientFirst,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
    },
    makeOrderButton: {
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginRight: '10px',
        borderRadius: '0px',
        backgroundColor: colors.primary,
        '&:hover':{
            backgroundColor: alpha(colors.primary, 0.9)
        }
    },
    makeOrderButtonOnlyBorder: {
        color: colors.primary,
        backgroundColor: colors.whiteAppbar,
        borderRadius: '0px',
        border: `solid 1.5px ${colors.primary}`,
        '&:hover':{
            backgroundColor: alpha(colors.primary, 0.2)
        }
    },
    totalPendingMarginSmall: {
        fontSize: '14px',
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px'
        },
    },
    confirmButton: {
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        backgroundColor: 'white',
        borderRadius: '0px',
        marginRight: '10px',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.2)
        }
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: '60%',
        borderRadius: '3px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'10px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          backgroundColor: '#ffffff'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        height: 24,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
        },
        
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
        },
        
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
        },
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    mainText: {
        color: colors.secondary, 
        fontWeight: '600', 
        fontSize: '18px', 
        marginBottom: '10px'
    },
    secondaryText: {
        color: colors.secondary, 
        fontWeight: '500', 
        fontSize: '15px', 
        marginBottom: '5px',
        marginTop: '-5px'
    },
    menuButton: {
        justifyContent: 'flex-start'
    },
    deleteButton: {
        color: colors.redError,
    },
    makeDiscountButton: {
        color: colors.orangeEdit,
    },
    nextStateButton: {
        color: colors.greenCart,
    },
    askButton: {
        color: 'orange',
    },
    elementHolder: {
        display: 'flex',
        margin: '10px 20px 20px 20px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 14px 10px 14px',
        }
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            flex: 1, 
        }
    },
    elementImage: {
        height: '140px',
        width: '140px',
        borderRadius: '3px',
        marginRight: '6px'
    },
    elementInfoAndPrice: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    elementInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
        }
    },
    priceInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            marginLeft: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    currencyPrice: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '8px',
            fontWeight: '700'
        }
    },
    priceInfoResponsive: {
        display: 'none',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10px',
            display: 'flex',
            marginBottom: '20px',
            flexDirection: 'row',
            alignItems: 'center'
        },
    },
    leftInfoIcon: {
        height: '20px',
        opacity: '0.87',
    },
    leftInfoIconFilled: {
        height: '14px',
        width: '15px',
        padding: '0px 1px',
        margin: '1px 4.5px',
        opacity: '0.77',
        backgroundColor: 'black',
        color: colors.whiteAppbar,
        borderRadius: '2px'
    },
    leftInfoTag: {
        fontSize: '10px',
        fontWeight: '600',
    },
    rightInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            marginRight: '25px'
        }
    },
    elementText: {
        fontSize: '14px',
        fontWeight: '600',
        padding: '0px 15px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px',
        } 
    },
    elementHeader: {
        fontSize: '16px',
        fontWeight: '600',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementSecondary: {
        fontSize: '16px',
        lineHeight: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    elementFirstHeader: {
        fontSize: '25px',
        fontWeight: '700',
        // textAlign: 'center',
        color: colors.primary,
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementSecondary: {
        fontSize: '16px',
        lineHeight: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    userImage: {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        marginLeft: '-10px',
        marginRight: '-12px',
    },
    airTaxImage: {
        height: '60px',
        width: '60px',
        marginLeft: '20px',
    },
    divisionSummarize: {
        height: '1px', 
        marginLeft: '20px', 
        marginRight: '20px', 
        marginTop: '5px',
        marginBottom: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        animation: '$enter 350ms ease-in',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px'
          },
    },
    walletImage: {
        height: '60px',
        width: '60px',
        marginLeft: '27px',
    },
    countAndPriceHolder: {
        marginLeft: '10px', 
        display: 'flex',  
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    elementUnit: {
        textAlign: 'end',
        paddingLeft: '5px',
        fontSize: '16px',
        fontWeight: '600',
        color: colors.secondary,
    },
    elementUnitBlue: {
        textAlign: 'end',
        paddingLeft: '5px',
        fontSize: '18px',
        fontWeight: '700',
        color: colors.primary,
    },
    lightGrayDividerElement: {
        width: 'calc(100%-40px)',
        margin: '7px 20px',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.2',
    },
    lightGrayDividerModalPending: {
        width: '100%',
        margin: '7px auto',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.2',
    },
    calendarHolder: {
        marginTop: '5px', 
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px'
        } 
    },
    emptyContainer: {
        backgroundColor: colors.whiteAppbar,
        paddingTop: '100px',
        paddingBottom: '130px',
        marginTop: '10px', 
        marginBottom: '10px',
        border: `solid 2.5px ${colors.secondary}`
    },
    emptyIconContainer: {
        position: 'relative',
        height: '130px',
        width: '130px',
        margin: '0px auto 0px auto',
        display: 'block',
        
    },
    emptyIcon: {
        position: 'absolute',
        height: '130px',
        width: '130px',
        color: colors.secondary,
    },
    emptyPrimaryText:{
        textAlign: 'center',
        fontWeight: '600',
    },
    emptySecondaryText: {
        textAlign: 'center',
        fontSize: '14px'
    },
    pendingOrdersHolder: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        } 
    },
    pendingOrdersTotalHolder: {
        paddingLeft: '40px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingLeft: '0px'
        } 
    }
}));

export default function OrdersPage(){
    const history = useHistory()
    const location = useLocation()
    const locationUseRef = useRef()
    locationUseRef.current = location
    const classes = useStyles();
    const [totalOrders, setTotalOrders] = useState(0)
    const [user, setUser] = useState()
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openDeniedRequestSnack, setOpenDeniedRequestSnack] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [guideHouse, setGuideHouse] = useState('')
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [username, setUsername] = useState('')
    const [usernameValue, setUsernameValue] = useState('')
    const [userSuggestions, setUserSuggestions] = useState([])
    const [anchorElStatuses, setAnchorElStatuses] = useState(null);
    const [showAllOrders, setShowAllOrders] = useState(false)
    const [ordersPendingPayment, setOrdersPendingPayment] = useState([])
    const [openOrdersPendingPaymentModal, setOpenOrdersPendingPaymentModal] = useState(false)
    const [gettingCheckoutUrlOrderId, setGettingCheckoutUrlOrderId] = useState("")
    const [openGetCheckoutErrorSnack, setOpenGetCheckoutErrorSnack] = useState(false)
    const openStatuses = Boolean(anchorElStatuses);

    const accessToken = useSelector((state) => state.user_store.accessToken)
    const userLogged = useSelector((state) => state.user_store.user)
    const searchOrderParameters = useSelector((state) => state.orders_store.searchOrderParameters)
    const currentOrders = useSelector((state) => state.orders_store.currentOrders)
    const [loadingOrders, setLoadingOrders] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        const getInitialQueryParams = () => {
            try{
                const newParams = getQueryParams()
                console.log(newParams) 
                if(newParams.queryHasParameters){
                    dispatch(update_search_order_params_action(newParams.params))
                }
            } catch(error){
                console.log(`ENCONTRE ERROR ${error}`)
            }
        }
        getInitialQueryParams() 
    }, [])

    useEffect(() => {
        const abort = {current: false}
        const fetchOrders = async () => {
            try{
                setLoadingOrders(true)
                if(userLogged){
                    if(searchOrderParameters.userId){
                        if(!userLogged.isImportAdmin && searchOrderParameters.userId !== userLogged._id){
                            setOpenDeniedRequestSnack(true)
                            return
                        }
                        if(userLogged.isImportAdmin){
                            setShowAllOrders(true)
                        }
                    }
                    if(!user){
                        setUser(userLogged)
                    }
                    let extraParams = ""
                    if(searchOrderParameters.statusToSearch !== 'Todos'){
                        extraParams += `&status=${searchOrderParameters.statusToSearch? searchOrderParameters.statusToSearch : 'Todos'}`
                    }
                    if(searchOrderParameters.guideHouseToSearch){
                        extraParams += `&guideHouse=${searchOrderParameters.guideHouseToSearch}`
                    }
                    if(searchOrderParameters.userId){
                        extraParams += `&userId=${searchOrderParameters.userId}`
                    }
                    if(searchOrderParameters.orderId){
                        extraParams += `&orderId=${searchOrderParameters.orderId}`
                    }
                    let urlRequest = `/api/orders/ofuser/${userLogged._id}/?page=${searchOrderParameters.page? searchOrderParameters.page+1 : 1}&limit=${searchOrderParameters.limit? searchOrderParameters.limit : 20}${extraParams}` 
                    if(userLogged.isImportAdmin){
                        urlRequest = searchOrderParameters?.userId? `/api/orders/ofuser/${searchOrderParameters.userId}/?page=${searchOrderParameters.page? searchOrderParameters.page+1 : 1}&limit=${searchOrderParameters.limit? searchOrderParameters.limit : 20}${extraParams}`  : `/api/orders/?page=${searchOrderParameters.page? searchOrderParameters.page+1 : 1}&limit=${searchOrderParameters.limit? searchOrderParameters.limit : 20}${extraParams}`
                    }
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}` }
                    };
                    let result = await fetch(`${url}${urlRequest}`, requestOptions)
                    if(result.status === 200  && !abort.current){
                        let dataResult = await result.json()
                        setTotalOrders(dataResult.count)
                        dispatch(update_orders_action(dataResult.orders, searchOrderParameters.page))
                        setLoadingOrders(false)
                    }
                    else{
                        setOpenApiErrorSnack(true)
                        if(!abort.current) setLoadingOrders(false)
                    }
                }
                
            } catch(error){
                setOpenApiErrorSnack(true)
            }
        }
        fetchOrders()
        return () => {
            abort.current = true
        }
    }, [searchOrderParameters, userLogged])

    useEffect(() => {
        const abort = {current: false}
        const fetchUsers = async () => {
            try{
                if(username?.length <= 2){
                    setLoadingUsers(false)
                    setUserSuggestions([])
                    return;
                }
                if(!abort.current){
                    setLoadingUsers(true)
                }
                if(accessToken){
                    let urlRequest = `/api/accountImport/usersuggest/?page=${1}&limit=${searchOrderParameters.limit}&userToSearch=${username}`
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}` }
                    };
                    let result = await fetch(`${url}${urlRequest}`, requestOptions)
                    if(result.status === 200){
                        if(!abort.current){
                            let dataResult = await result.json()
                            setUserSuggestions(dataResult)
                            setLoadingUsers(false)
                        }
                    }
                    else{
                        if(!abort.current){
                            setLoadingUsers(false)
                        }
                    }
                }
                
            } catch(error){
                console.log(error)
                setLoadingUsers(false)
            }
        }
        fetchUsers()
        return(
            abort.current = false
        )
    }, [username])

    useEffect(() => {
        const getOrdersPendingPayment = async () => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json',
                            'Authorization': `${accessToken}`
                },
            };
            let result = await fetch(`${url}/api/orders/getPendingPaymentOrders/`, requestOptions);
            if(result.status === 200){
                const data = await result.json()
                if(data.count > 0){
                    setOrdersPendingPayment(data.orders)
                    setOpenOrdersPendingPaymentModal(true)
                }
            }
            else{
                console.log("ERROR GETTING PENDING PAYMENT ORDERS")
            }
        }
        if(accessToken) getOrdersPendingPayment()
    }, [accessToken])

    //*QUERY PARAMS MANAGEMENT
    const getQueryParams = () => {
        const search = locationUseRef.current.search
        const queryParams = new URLSearchParams(search)
        let queryHasParameters = false
        let newParams = {...searchOrderParameters}
        if (queryParams.has('page')) {
            let page = parseInt(queryParams.get('page'))
            if(page){
                newParams.page = page
                queryHasParameters = true
            }
        }
        if (queryParams.has('userId')) {
            const userId = queryParams.get('userId')
            newParams.userId = userId
            queryHasParameters = true
        }
        if (queryParams.has('userFullName')) {
            const userFullName = queryParams.get('userFullName')
            newParams.userFullName = userFullName
            queryHasParameters = true
        }
        if (queryParams.has('orderId')) {
            const orderId = queryParams.get('orderId')
            newParams.orderId = orderId
            queryHasParameters = true
        }
        if (queryParams.has('orderNumber')) {
            const orderNumber = queryParams.get('orderNumber')
            newParams.orderNumber = orderNumber
            queryHasParameters = true
        }
        if (queryParams.has('statusToSearch')) {
            const statusToSearch = queryParams.get('statusToSearch')
            newParams.statusToSearch = statusToSearch
            queryHasParameters = true
        }
        if (queryParams.has('orderBy')) {
            newParams.orderBy = queryParams.get('orderBy')
            queryHasParameters = true
        }
        return {
            queryHasParameters: queryHasParameters,
            params: newParams
        }
    }

    const manageQueryParams = (type, newValue) => {
        const search = locationUseRef.current.search
        const queryParamsToSearch = new URLSearchParams(search)
        let queryHasParameters = false
        
        const queryParams = {}
        if (type === "user") {
            queryParams["userId"] = newValue._id
            queryParams["userFullName"] = newValue.fullName
            queryHasParameters = true
        }
        else{
            if(queryParamsToSearch.has('userId')) {
                queryParams.userId = queryParamsToSearch.get('userId')
                queryHasParameters = true
            }
            if(queryParamsToSearch.has('userFullName')) {
                queryParams.userFullName = queryParamsToSearch.get('userFullName')
                queryHasParameters = true
            }
        }
        if (type === "order") {
            queryParams["orderId"] = newValue._id
            queryParams["orderNumber"] = newValue.orderNumber
            queryHasParameters = true
        }
        else{
            if(queryParamsToSearch.has('orderId')) {
                queryParams.orderId = queryParamsToSearch.get('orderId')
                queryHasParameters = true
            }
            if(queryParamsToSearch.has('orderNumber')) {
                queryParams.orderNumber = queryParamsToSearch.get('orderNumber')
                queryHasParameters = true
            }
        }
        if (type === "page") {
            queryParams["page"] = newValue
            queryHasParameters = true
        }
        else{
            queryParams.page = 0
            queryHasParameters = true
        }
        if (type === "statusToSearch") {
            queryParams["statusToSearch"] = newValue
            queryHasParameters = true
        }
        else{
            if(queryParamsToSearch.has('statusToSearch')) {
                queryParams.statusToSearch = queryParamsToSearch.get('statusToSearch')
                queryHasParameters = true
            }
        }
        if (type === "orderBy") {
            queryParams["orderBy"] = newValue
            queryHasParameters = true
        }
        else{
            if(queryParamsToSearch.has('orderBy')) {
                queryParams.orderBy = queryParamsToSearch.get('orderBy')
                queryHasParameters = true
            }
        }
        if(queryHasParameters){
            let params = new URLSearchParams(queryParams)
            history.replace({
                search: params.toString(),
            })
        }
    }

    const toSupportWhatsapp = () => {
        const textMessage = `Hola, tengo problemas para realizar mi pago`
        const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
        window.open(whatsapUrl)
    }

    const onCLickAllOrders = () => {
        history.push('/orders')
    }

    const handleChangeGuideHouse = (event) => {
        setGuideHouse(event.target.value)
    }

    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }

    const handleCloseGetCheckoutUrlErrorSnack = () => {
        setOpenGetCheckoutErrorSnack(false)
    }

    const handleCloseDeniedRequestSnack = () => {
        setOpenDeniedRequestSnack(false)
    }

    const handleChangePage = (event, newPage) => {
        dispatch(update_search_order_params_action({
            ...searchOrderParameters,
            page: newPage
        }))
        manageQueryParams("page", newPage)
      };

    const getInfoText = () => {
        if(userLogged){
            if(user && searchOrderParameters?.userId && userLogged.isImportAdmin && currentOrders.orders?.length > 0){
                if(searchOrderParameters?.orderId){
                    return `PEDIDO #${searchOrderParameters?.orderNumber} DE ${searchOrderParameters?.userFullName?.toUpperCase()}`
                }
                return `PEDIDOS DE ${searchOrderParameters?.userFullName}`
            }
            else{
                if(userLogged.isImportAdmin){
                    if(searchOrderParameters.statusToSearch === 'Todos') return 'TODOS LOS PEDIDOS'
                    else {
                        try{
                            return 'EN ESTADO ' + searchOrderParameters.statusToSearch.toUpperCase()
                        }
                        catch(e){
                            console.log(e)
                            return 'CARGANDO'
                        }
                    }
                }
            }
        }
        return "MIS PEDIDOS"
    }

    const handleClose = () => {
        setOpenModal(false)
    }
    const handleClickStatuses = (event) => {
        setAnchorElStatuses(event.currentTarget);
    };
    const handleCloseStatuses = (element) => {
        setAnchorElStatuses(null);
        if (element !== null){
            dispatch(update_search_order_params_action({
                ...searchOrderParameters,
                page: 0,
                statusToSearch: element,
            }))   
        }
        manageQueryParams("page", 0)
        manageQueryParams("statusToSearch", element)
    };

    const onClickConfirmFilter = () => {
        console.log(usernameValue)
        let fullName = ""
        if(usernameValue?.name) fullName = usernameValue.name.toUpperCase()
        if(usernameValue?.lastName) fullName = fullName + " " + usernameValue.lastName.toUpperCase()
        dispatch(update_search_order_params_action({
            ...searchOrderParameters,
            page: 0,
            guideHouseToSearch: guideHouse,
            userId: usernameValue?._id? usernameValue._id : '',
            userName: fullName
        }))
        manageQueryParams("page", 0)
        manageQueryParams("user", {_id: usernameValue?._id, fullName: fullName})
        setOpenModal(false)
    }

    const filterOptions = (options, state) => {
        let newOptions = [];
        options.forEach((element) => {
            newOptions.push(element)
        });
        return newOptions;
    };

    const getPages = () => {
        if(totalOrders === 0){
            return 1
        }
        if(totalOrders % searchOrderParameters.limit === 0) return totalOrders / searchOrderParameters.limit
        return Math.trunc(totalOrders/searchOrderParameters.limit)+1
    }

    const getCheckoutUrlPendingPayments = async (order) => {
        if(gettingCheckoutUrlOrderId !== "") return
        setGettingCheckoutUrlOrderId(order._id)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
            },
        };
        let result = await fetch(`${url}/api/orders/payCheckout/?orderId=${order._id}`, requestOptions);
        if(result.status === 200){
            const data = await result.json()
            window.location.href = data.stripeUrl;
            setGettingCheckoutUrlOrderId("")
        }
        else{
            setGettingCheckoutUrlOrderId("")
            setOpenGetCheckoutErrorSnack(true)
        }
    }

    const handleCloseOrdersPendingPaymentModal = () => {
        setOpenOrdersPendingPaymentModal(false)
    }

    if(openApiErrorSnack){
        return(
            <div>
                <Navbar />
                <NoConnection />
                <Footer hideXs={true} />
            </div>
        )
    }
    else{
        return(
            <React.Fragment>
                <div style={{backgroundColor: colors.cartBlueBackground, minHeight: '100vh', paddingBottom: '20px'}}>
                    <Navbar />
                    <Snackbar open={openDeniedRequestSnack} autoHideDuration={10000} onClose={handleCloseDeniedRequestSnack}>
                        <Alert onClose={handleCloseDeniedRequestSnack} severity="error">
                            NO TIENE ACCESO A ESTOS PEDIDOS.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                        <Alert onClose={handleCloseApiErrorSnack} severity="error">
                            NO SE PUDO CARGAR. REVISE SU CONEXION A INTERNET.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openGetCheckoutErrorSnack} autoHideDuration={3000} onClose={handleCloseGetCheckoutUrlErrorSnack}>
                        <Alert onClose={handleCloseGetCheckoutUrlErrorSnack} severity="error">
                            NO SE PUDO PROCESAR PAGO, REVISE SU CONEXION A INTERNET E INTENTELO DE NUEVO.
                        </Alert>
                    </Snackbar>
                    <Modal
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="filter states"
                        aria-describedby="data necessary to filter states"
                    >
                        <div className={classes.modalPaper}>
                            <Typography className={classes.mainText}>Filtrar por:</Typography>
                            <Typography>Estado</Typography>
                            <Button
                                variant="contained"
                                aria-label="more"
                                aria-controls="long-menu-drawer"
                                aria-haspopup="true"
                                className={classes.inputHolder}
                                onClick={handleClickStatuses}
                                endIcon={<ArrowDropDown />}
                                classes={{
                                    label: classes.menuButton,
                                }}
                            >{searchOrderParameters.statusToSearch.toUpperCase()}</Button>
                            <Menu
                                id="long-menu-drawer"
                                anchorEl={anchorElStatuses}
                                keepMounted
                                open={openStatuses}
                                onClose={() => handleCloseStatuses(null)}
                                //PaperProps={{}
                            >
                                {['Todos','Solicitado', 'Confirmado', 'Embalado', 'Enviado', 'Recibido', 'Cancelado'].map((element) => (
                                    <MenuItem key={element} onClick={() => handleCloseStatuses(element)}>
                                        {element}
                                    </MenuItem>
                                ))}
                            </Menu>
                            {userLogged?.isImportAdmin &&
                            <React.Fragment>
                            {/* <Typography>Guía House</Typography>
                            <div className={classes.inputHolder}>
                                <InputBase
                                    elevation={4}
                                    placeholder="--"
                                    value={guideHouse}
                                    onChange={handleChangeGuideHouse}
                                    type='number'
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search', maxLength:20 }}
                                />
                            </div> */}
                            <Typography style={{marginTop: '15px'}}>Usuario</Typography>
                            <div className={classes.inputHolder}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={userSuggestions}
                                inputValue={username}
                                value={usernameValue}
                                onChange={(event, newValue) => {
                                    setUsernameValue(newValue)
                                }}
                                noOptionsText='Sin opciones'
                                onInputChange={(event, newValue) => {
                                    setUsername(newValue)
                                }}
                                getOptionLabel={(option) => option? `${option.name} ${option.lastName}` : ''}
                                style={{ width: '100%' }}
                                loading={loadingUsers}
                                filterOptions={filterOptions}
                                renderOption={(option) => (
                                    <React.Fragment>
                                    <Typography>{`${option.name} ${option.lastName}`}</Typography>
                                    </React.Fragment>
                                )}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label="--" 
                                        variant="outlined" 
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                            <React.Fragment>
                                                {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            ),
                                        }}
                                    />
                                }
                            /> 
                            </div>
                            </React.Fragment>
                            }
                            <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                                <Button variant='contained' onClick={onClickConfirmFilter} className={classes.confirmButton}>Aplicar</Button>
                            </div>
                            
                        </div>
                    </Modal>
                    <Modal
                        open={openOrdersPendingPaymentModal}
                        onClose={handleCloseOrdersPendingPaymentModal}
                        aria-labelledby="orders pending payment"
                        aria-describedby="data necessary to orders pending payment"
                    >
                        <div className={classes.modalPaper} style={{maxWidth: '650px'}}>
                            <Typography className={classes.mainText}>PEDIDOS PENDIENTE A PAGO EN LINEA (STRIPE):</Typography>
                            <Typography className={classes.secondaryText}>TIENE {ordersPendingPayment.length} PEDIDO{ordersPendingPayment.length > 1? "S" : ""} PENDIENTE{ordersPendingPayment.length > 1? "S" : ""} A PAGO:</Typography>
                            <Typography 
                                className={classes.secondaryTextWarning} 
                                style={{color: colors.orangeEditDark, fontSize: '13px'}}
                            >
                                SI PRESENTA PROBLEMAS PARA REALIZAR EL PAGO PUEDE COMUNICARSE CON NOSOTROS A TRAVES DE NUESTRO SOPORTE <span onClick={toSupportWhatsapp} style={{fontWeight: '600', textDecoration: 'underline', cursor: 'pointer'}}>PULSANDO AQUI</span>
                            </Typography>
                            <div className={classes.lightGrayDividerModalPending}/>
                            {ordersPendingPayment.map((order, index) => {
                                    return(
                                        <div key={index}>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <div className={classes.pendingOrdersHolder}>
                                                    <div>
                                                        <Typography className={classes.rowInfoText}>PEDIDO #{order.orderNumber}</Typography>
                                                        <Typography className={classes.rowInfoText}>{getDate(order.currentStatusDate)}</Typography>
                                                    </div>
                                                    <div className={classes.pendingOrdersTotalHolder}>
                                                        <Typography className={classes.rowInfoText}>TOTAL</Typography>
                                                        <Typography className={classes.totalPendingMarginSmall} style={{fontWeight: '700', color: colors.primary}}>{getTotalOrder(order)} USD</Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    {gettingCheckoutUrlOrderId === order._id?
                                                    <Button
                                                    className={classes.makeOrderButtonOnlyBorder} style={{width: '82px'}}
                                                    classes={{
                                                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                                    }}
                                                    ><CircularProgress size={24} style={{color: colors.primary}}/></Button>
                                                    :
                                                    <Button variant='contained' 
                                                        onClick={() => {getCheckoutUrlPendingPayments(order)}} className={classes.makeOrderButtonOnlyBorder}
                                                        disabled={gettingCheckoutUrlOrderId !== ""}>PAGAR</Button>
                                                    }
                                                </div>
                                            </div>

                                            <div className={classes.lightGrayDividerModalPending}/>
                                        </div>
                                    )
                                })
                            }
                            <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                                <Button variant='contained' onClick={handleCloseOrdersPendingPaymentModal} className={classes.confirmButton}>CERRAR</Button>
                            </div>
                            
                        </div>
                    </Modal>
                    {loadingOrders?
                    <React.Fragment>
                        <div className={classes.orderInfoHolder} style={{display: 'flex', alignItems: 'center'}}>
                            <TitleWithBottomArrow title={getInfoText()} page={"orders"}/>
                        </div>
                        <div className={classes.ordersHolder}>
                        <div className={classes.filtersHolder}>
                            <div>
                                <IconButton disabled={true} style={{padding: '10px', color: 'black'}}><BsFillFunnelFill style={{heigth: '18px', width: '18px'}}/></IconButton>
                            </div>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <IconButton
                                        disabled={true}
                                    >
                                        <ArrowForwardIos style={{transform: 'rotate(180deg)'}} />
                                    </IconButton>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>0-0/0</Typography>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>Pág: 0/0</Typography>
                                    <IconButton
                                        disabled={true}
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {[1,2,3,4,5,6,7,8,9,10].map((number) => (
                            <div key={number}>
                                <div className={classes.rowHolder}>
                                    <div className={classes.rowStatusHolder} style={{backgroundColor: colors.secondary, border: `2.5px solid ${colors.secondary}`}}>
                                        <Skeleton className={classes.skeletonHeigth} style={{color: colors.whiteAppbar}} />
                                    </div>
                                    <div className={classes.rowOrderInfoHolder} style={{border: `2.5px solid ${colors.secondary}`}}>
                                    <div style={{display: 'flex'}}>
                                        <div className={classes.rowUserAddresseeAndProductsSummarize}>
                                            <div className={classes.rowUserAndAddresseeInfo}>
                                                <div className={classes.rowUserInfo}>
                                                    <div className={classes.rowUserSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Assignment className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} style={{width: '20px'}} />
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px', }}>
                                                            <MonetizationOn className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} style={{width: '100px'}}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowUserSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <AccountCircle className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} style={{width: '180px'}}/>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowUserSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Email className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth}  style={{width: '160px'}}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowUserSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Phone className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth}  style={{width: '100px'}}/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className={classes.rowUserAndAddresseeDivider} />
                                                <div className={`${classes.rowAddresseeInfo} ${classes.rowAddresseeInfoHideXs}`}>
                                                    <div className={classes.rowAddresseeSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center' }}>
                                                            <AssignmentInd className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth}  style={{width: '180px'}}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowAddresseeSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <CreditCard className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth}  style={{width: '100px'}}/>
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px', }}>
                                                            <Phone className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} style={{width: '75px'}}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowAddresseeSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                                            <PersonPinCircle className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} style={{width: '180px', height: '80px'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.rowUserAddresseeAndProductsSummarizeDivider} />
                                            <div className={classes.rowProductSummarizeAndOptions}>
                                                <div className={classes.rowProductSummarize}>
                                                    <div className={classes.rowProductSummarizeSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                                            <Skeleton className={classes.skeletonHeigth} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowProductSummarizeSubInfo}>
                                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                                            <Skeleton className={classes.skeletonHeigth} /> 
                                                            <AttachMoney className={classes.rowInfoIconMain}/>
                                                            <Skeleton className={classes.skeletonHeigth} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '3px'}}>
                                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                                            <Skeleton className={classes.skeletonHeigth} /> 
                                                            <AttachMoney className={classes.rowInfoIcon}/>
                                                            <Skeleton className={classes.skeletonHeigth} />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.rowUserAddresseeAndProductsSummarizeDivider} />
                                        <div className={classes.rowOptions}>
                                            <div>
                                                <div>
                                                    <Skeleton className={classes.skeletonHeigth} style={{width: '30px', height: '30px'}} variant="circle"/>
                                                </div>
                                                <div style={{marginTop: '7px'}}>
                                                    <Skeleton className={classes.skeletonHeigth} style={{width: '30px', height: '30px'}} variant="circle"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div className={classes.orderInfoHolder} style={{display: 'flex', alignItems: 'center'}}>
                    {showAllOrders?
                        <TitleWithBottomArrow title={getInfoText()} backButtonAction={onCLickAllOrders} page={"orders"}/>
                        :
                        <TitleWithBottomArrow title={getInfoText()} page={"orders"}/>
                    }
                    </div>
                    <div className={classes.ordersHolder}>
                        <div className={classes.filtersHolder}>
                            <div>
                                <IconButton onClick={() => {setOpenModal(true)}} style={{padding: '10px', color: 'black'}}><BsFillFunnelFill style={{heigth: '18px', width: '18px'}}/></IconButton>
                            </div>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <IconButton
                                        disabled={searchOrderParameters.page === 0}
                                        onClick={(event) => {
                                                if(searchOrderParameters.page !== 0){
                                                    handleChangePage(event, searchOrderParameters.page - 1)
                                                }
                                            }
                                        }
                                    >
                                        <ArrowForwardIos style={{transform: 'rotate(180deg)'}} />
                                    </IconButton>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>{searchOrderParameters.page * searchOrderParameters.limit !== 0? searchOrderParameters.page * searchOrderParameters.limit: 1}-{(searchOrderParameters.page + 1) * searchOrderParameters.limit > totalOrders? totalOrders: (searchOrderParameters.page + 1) * searchOrderParameters.limit}/{totalOrders}</Typography>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>Pág: {searchOrderParameters.page + 1}/{totalOrders !== 0? getPages() : 1}</Typography>
                                    <IconButton
                                        disabled={totalOrders === 0 || Math.trunc(totalOrders/searchOrderParameters.limit) === searchOrderParameters.page}
                                        onClick={(event) => {
                                                handleChangePage(event, searchOrderParameters.page + 1)
                                            }
                                        }
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {currentOrders?.orders?.length > 0?
                        <React.Fragment>
                            {currentOrders.orders.map((order) => (
                                <OrderRow key={order._id} order={order} isOpen={(searchOrderParameters?.orderId && searchOrderParameters?.orderId === order._id)? true : false} isImportAdmin={user.isImportAdmin} user={user}/>
                            ))}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {searchOrderParameters.page === 0 &&
                                <div className={classes.emptyContainer}>
                                    <div className={classes.emptyIconContainer}>
                                        <AssignmentLate className={classes.emptyIcon} />
                                    </div>
                                    <Typography className={classes.emptyPrimaryText}>NO TIENE PEDIDOS</Typography>
                                    <Typography className={classes.emptySecondaryText}>CUANDO HAGA UN PEDIDO APARECERA AQUI :)</Typography>
                                </div>
                            }
                        </React.Fragment>
                        }
                        <div className={classes.filtersHolder} style={{marginTop: '7px', border: `solid 2.5px ${colors.secondary}`}}>
                            <div>
                                <IconButton onClick={() => {setOpenModal(true)}} style={{padding: '10px', color: 'black'}}><BsFillFunnelFill style={{heigth: '18px', width: '18px'}}/></IconButton>
                            </div>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <IconButton
                                        disabled={searchOrderParameters.page === 0}
                                        onClick={(event) => {
                                                if(searchOrderParameters.page !== 0){
                                                    handleChangePage(event, searchOrderParameters.page - 1)
                                                }
                                            }
                                        }
                                    >
                                        <ArrowForwardIos style={{transform: 'rotate(180deg)'}} />
                                    </IconButton>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>{searchOrderParameters.page * searchOrderParameters.limit !== 0? searchOrderParameters.page * searchOrderParameters.limit: 1}-{(searchOrderParameters.page + 1) * searchOrderParameters.limit > totalOrders? totalOrders: (searchOrderParameters.page + 1) * searchOrderParameters.limit}/{totalOrders}</Typography>
                                    <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>Pág: {searchOrderParameters.page + 1}/{totalOrders !== 0? getPages() : 1}</Typography>
                                    <IconButton
                                        disabled={totalOrders === 0 || Math.trunc(totalOrders/searchOrderParameters.limit) === searchOrderParameters.page}
                                        onClick={(event) => {
                                                handleChangePage(event, searchOrderParameters.page + 1)
                                            }
                                        }
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                }
                </div>
                <Footer hideXs={true} />
            </React.Fragment>
        )
    }
}

function OrderRow({order, isOpen, isImportAdmin, user}) {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const locationUseRef = useRef()
    locationUseRef.current = location
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteSuccessSnack, setOpenDeleteSuccessSnack] = useState(false)
    const [openDeleteApiErrorSnack, setOpenDeleteApiErrorSnack] = useState(false)
    const [openDetails, setOpenDetails] = React.useState(isOpen);
    const [guideHouse, setGuideHouse] = useState(order.houseGuide !== undefined? order.houseGuide : '')
    const [orderProducts, setOrderProducts] = useState([])
    const [guideHouseError, setGuideHouseError] = useState('')
    const [paymentTypeObservation, setPaymentTypeObservation] = useState(order.paymentObservation)
    const [paymentTypeObservationError, setPaymentTypeObservationError] = useState("")
    const [paymentTypeObservationErrorOpen, setPaymentTypeObservationErrorOpen] = useState(false)
    const [guideHouseErrorOpen, setGuideHouseErrorOpen] = useState(false)
    const [openModal, setOpenModal] = useState({open:false, makeDiscount: false})
    const [openEditModal, setOpenEditModal] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(order.currentStatus)
    const [currentStatusDate, setCurrentStatusDate] = useState(order.currentStatusDate)
    const [statuses, setStatuses] = useState(order.statuses)
    const [updatingStateOrder, setUpdatingStateOrder] = useState(false)
    const [updatingEditOrder, setUpdatingEditOrder] = useState(false)

    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openUnavailableErrorSnack, setOpenUnavailableErrorSnack] = useState(false)
    const [snackProps, setSnackProps] = useState({open: false, severity: "success", text: ""})
    const [openUndeliveredErrorSnack, setOpenUndeliveredErrorSnack] = useState(false)
    const [openUndeliveredImportErrorSnack, setOpenUndeliveredImportErrorSnack] = useState(false)
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
    const [openEditSuccessSnack, setOpenEditSuccessSnack] = useState(false)

    const [gettingCheckoutUrl, setGettingCheckoutUrl] = useState(false)

    const [anchorElUserOptions, setAnchorElUserOptions] = useState(null);

    const [notDelivers, setNotDelivers] = useState({
        count: 0,
        price: 0,
        discount: 0,
        wallet: 0,
    })

    const [allFastDelivery, setAllFastDelivery] = useState(false)
    const openUserOptions = Boolean(anchorElUserOptions);

    const userLogged = useSelector((state) => state.user_store.user)
    const accessToken = useSelector((state) => state.user_store.accessToken)
    

    useEffect(() => {
        const search = locationUseRef.current.search
        let queryParams = new URLSearchParams(search)
        if (queryParams.has('successPaymentOrderId')) {
            if(order._id === queryParams.get('successPaymentOrderId')) 
            setSnackProps({
                open: true,
                severity: 'success',
                text: `PAGO EXITOSO DE PEDIDO #${order.orderNumber}, INICIANDO PROCESO DE ENTREGA`
            })
            setCurrentStatus("Confirmado")
            setCurrentStatusDate(Date.now())
            queryParams = new URLSearchParams()
            history.replace({
                search: queryParams.toString(),
            })
        }
        calculateOrderValues(order.productsConfirmed)
        if(order?.totalProductsCancelled?.count > 0){
            setNotDelivers({
                count: order.totalProductsCancelled.count,
                price: order.totalProductsCancelled.price,
                discount: order.totalProductsCancelled.discount,
                wallet: order.totalProductsCancelled.wallet
            })
        }
        if(allFastDeliveryCalculate()) setAllFastDelivery(true)
        else setAllFastDelivery(false)
    }, [])

    // useLayoutEffect(() => {
    //     function updateSize() {
    //       setWidthSize([window.innerWidth]);
    //     }
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    const calculateOrderValues = (newProductsConfirmed) => {
        
        let products = order.productsConfirmed
        if(products?.length > 0){
            products.sort()
            products.sort((a, b) => {
                if(a.product.fastDelivery && !b.fastDelivery){
                    return -1
                }
                if(b.product.fastDelivery && !a.fastDelivery){
                    return 1
                }
                if(a.product.user < b.product.user){
                    return -1
                }
                if(a.product.user > b.product.user){
                    return 1
                }
                if(a.product.user === b.product.user){
                    return 0
                }
            })
        }
        
        if(newProductsConfirmed){
            products = newProductsConfirmed
            setOrderProducts(newProductsConfirmed)
        }
        setOrderProducts(products)
    }

    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }
    const handleCloseUnavailableErrorSnack = () => {
        setOpenUnavailableErrorSnack(false)
    }
    const handleCloseSnackProps = () => {
        setSnackProps({
            ...snackProps,
            open: false,
            })
    }
    const handleCloseUndeliveredErrorSnack = () => {
        setOpenUndeliveredErrorSnack(false)
    }
    const handleCloseUndeliveredImportErrorSnack = () => {
        setOpenUndeliveredImportErrorSnack(false)
    }
    const handleCloseSuccessSnack = () => {
        setOpenSuccessSnack(false)
    }

    const handleCloseEditSuccessSnack = () => {
        setOpenEditSuccessSnack(false)
    }

    const onClickConfirm = () => {
        if(isImportAdmin){
            setOpenModal({open:true, makeDiscount: false})
        }
    }

    const onClickAsk = (orderId) => {
        const textMessage = `Hola, me gustaria preguntarle sobre mi pedido: https://sevendeimport.com/orders/${user._id}/${orderId}`
        const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
        window.open(whatsapUrl)
    }

    const allFastDeliveryCalculate = () => {
        for(const p of order.productsConfirmed){
            if(!p.product.fastDelivery) return false
        }
        return true
    }

    const handleClose = () => {
        setOpenModal({open:false, makeDiscount: false})
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false)
    }

    const getNextState = () => {
        if(currentStatus === 'Solicitado') return '"Confirmado"'
        if(currentStatus === 'Confirmado') {
            if(allFastDelivery){
                return "Recibido"
            }
            return '"Embalado"'
        }
        if(currentStatus === 'Embalado') return '"Enviado"'
        if(currentStatus === 'Enviado') return '"Recibido"'
    }

    const handleChangeGuideHouse = (event) => {
        if(guideHouseError){
            setGuideHouseError("")
            handleGuideHouseErrorTooltipClose()
        }
        setGuideHouse(event.target.value);
    };

    const handlePaymentObservationErrorTooltipClose = () => {
        setPaymentTypeObservationErrorOpen(false)
    }

    const handlePaymentObservationErrorTooltipOpen = () => {
        setPaymentTypeObservationErrorOpen(true)
    }

    const handleChangePaymentObservation = (event) => {
        if(paymentTypeObservationError){
            setPaymentTypeObservationError("")
            handlePaymentObservationErrorTooltipClose()
            
        }
        setPaymentTypeObservation(event.target.value);
    };

    const handleGuideHouseErrorTooltipClose = () => {
        setGuideHouseErrorOpen(false);
    };
    const handleGuideHouseErrorTooltipOpen = () => {
        setGuideHouseErrorOpen(true);
    };

    const getBodyState = () => {
        if(currentStatus === 'Solicitado'){
            return {_id: order._id, history: statuses, newStatus: 'Confirmado', paymentObservation: paymentTypeObservation}
        }
    }

    const onClickNextState = async () => {
        if(currentStatus === "Solicitado"){
            if(!paymentTypeObservation){
                setPaymentTypeObservationError("Debe poner una Observacion de Pago")
                handlePaymentObservationErrorTooltipOpen()
                return
            }
            for (let index = 0; index < orderProducts.length; index++) {
                const element = orderProducts[index]
                if(element.trackStatus?.currentStatus === "No Entregado"){
                    setOpenUnavailableErrorSnack(true)
                    // return
                }
            }
        }
        
        setUpdatingStateOrder(true)
        let body = getBodyState()
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
            },
            body: JSON.stringify(
                body  
            )
        };
        let result = await fetch(`${url}/api/orders`, requestOptions);
        if(result.status === 200){
            if(currentStatus === "Solicitado"){
                setPaymentTypeObservation("CASH")
            }
            setUpdatingStateOrder(false)
            setOpenSuccessSnack(true)
            
            const newDate = Date.now()
            setCurrentStatus(body.newStatus)
            setCurrentStatusDate(newDate)
            let newStatuses = [...statuses]
            newStatuses.push({status: body.newStatus, date: newDate})
            setStatuses(newStatuses)
            setOpenModal({open:false, makeDiscount: false})
        }
        else{
            setUpdatingStateOrder(false)
            setOpenApiErrorSnack(true)
        }
    }

    const onClickEditOrder = async () => {
        if(!guideHouse){
            setGuideHouseError("Debe introducir una Guía House válida.")
            setGuideHouseErrorOpen(true)
            return;
        }
        else{
            if(!parseInt(guideHouse)){
                setGuideHouseError("La Guía House debe ser un número.")
                setGuideHouseErrorOpen(true)
                return
            }
        }
        setUpdatingEditOrder(true)
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
            },
            body: JSON.stringify(
                {
                    orderId: order._id,
                    guideHouse: parseInt(guideHouse)
                }  
            )
        };
        let result = await fetch(`${url}/api/orders/editOrder`, requestOptions);
        if(result.status === 200){
            setUpdatingEditOrder(false)
            setOpenEditSuccessSnack(true)
            setOpenEditModal(false)
        }
        else{
            setUpdatingEditOrder(false)
            setOpenApiErrorSnack(true)
        }
    }

    

    const getCurrentStatusColor = () => {
        if(currentStatus === 'Solicitado') return colors.requestedDarkKhaki
        if(currentStatus === 'Confirmado') return colors.confirmedDarkCyan
        if(currentStatus === 'Embalado') return colors.packagingDarkMagenta
        if(currentStatus === 'Enviado') return colors.sendedDarkSeaGreen
        if(currentStatus === 'Recibido' || currentStatus === 'Entregado') return colors.recieveDarkGreen
        if(currentStatus === 'Cancelado') return colors.redError
    }

    const getCurrentStatusProductColor = (product) => {
        if(product.trackStatus){
            if(product.trackStatus.currentStatus === 'Chequeando Disponibilidad') return colors.requestedDarkKhaki
            if(product.trackStatus.currentStatus === 'Disponible') return colors.confirmedDarkCyan
            if(product.trackStatus.currentStatus === 'No Entregado') return colors.redError
            if(product.trackStatus.currentStatus === 'Confirmado') return colors.secondary
            if(product.trackStatus.currentStatus === 'Recibido' || product.trackStatus.currentStatus === "Entregado") return colors.recieveDarkGreen
        }
    }

    const getCurrentStatusProductImportColor = (productStatus) => {
        if(productStatus){
            if(productStatus === 'Chequeando Disponibilidad') return colors.requestedDarkKhaki
            if(productStatus === 'Disponible') return colors.confirmedDarkCyan
            if(productStatus === 'No Entregado') return colors.redError
            if(productStatus === 'Confirmado') return colors.secondary
            if(productStatus === 'Enviado') return colors.sendedDarkSeaGreen
            if(productStatus === 'Recibido' || productStatus === "Entregado") return colors.recieveDarkGreen
        }
    }

    // const getImportProductStatus = (product) => {
    //     if(product.trackStatus?.currentStatus !== 'Recibido'){
    //         return product.trackStatus?.currentStatus
    //     }
    //     else{
    //         if(currentStatus === 'Confirmado') return "En Almacen"
    //         if(currentStatus === 'Embalado') return "Embalado"
    //         if(currentStatus === 'Enviado') return "Enviado"
    //         if(currentStatus === 'Recibido') return "Recibido"
    //     }
        
    // }

    const OpenDeleteSuccessSnack = () => {
        setOpenDeleteModal(false)
        setOpenDeleteSuccessSnack(true)
    }
    const OpenDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(true)
    }
    const handleCloseDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(false)
        window.location.reload(false)
    }
    const handleCloseDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(false)
    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const toProductPage = (productId) => {
        if(isImportAdmin){
            history.push(`/products/detail/${productId}`);
        }
    }

    const handleClickUserOptions = (event) => {
        setAnchorElUserOptions(event.currentTarget);
    };
    const handleCloseUserOptions = () => {
        setAnchorElUserOptions(null);
    };

    const getSubtotalValue = (product, count, hasDiscountCoupon, payed) => {
        let discount = 0
        if(hasDiscountCoupon) discount = getProductDiscountForCoupon(product, order.discountCouponData.productDiscount)
        return reduceDecimalValue(((product.price - discount + calcSendingCost(product))*count) - payed)
    }

    const getCheckoutUrl = async () => {
        if(gettingCheckoutUrl) return
        setGettingCheckoutUrl(true)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
            },
        };
        let result = await fetch(`${url}/api/orders/payCheckout/?orderId=${order._id}`, requestOptions);
        if(result.status === 200){
            const data = await result.json()
            window.location.href = data.stripeUrl;
            setGettingCheckoutUrl(false)
        }
        else{
            setGettingCheckoutUrl(false)
            setSnackProps({
                open: true,
                severity: 'error',
                text: "No se pudo procesar pago, revise su conexion a internet e intentelo de nuevo"
            })
        }
    }



    const ElementCard = ({element, index, address, addDeliveryCost, showUser, firstImport, firstDelivery, products, order}) => {
        const productStatus = element.trackStatus?.currentStatus? element.trackStatus.currentStatus : ""

        const checkAbovePassed = (aboveNumber) => {
            try{
            const filterProducts = products.filter((p) => {return(p.product.user._id === element.product.user._id && p.product.fastDelivery === element.product.fastDelivery)})
            let total = 0
            for(const product of filterProducts){
                total += ((product.product.price+calcSendingCost(product.product, order.sendingCost))*product.count)
                if(order.hasDiscountCoupon && order.discountCouponData?.productDiscount > 0){
                    total -= getProductDiscountForCoupon(product.product, order.discountCouponData.productDiscount)
                }
            }
            if(total >= aboveNumber) return true
            return false
            }
            catch(e){
                console.log(e)
                console.log("ORDEN: ", order._id)
                return false
            }
        }

        

        const getDeliveryCost = (productUser, address, fastDelivery) => {
            if(order?.deliveryCostsPerUser?.length > 0){
                for (let index = 0; index < order.deliveryCostsPerUser.length; index++) {
                    const element = order.deliveryCostsPerUser[index];
                    if(element.user._id === productUser._id && fastDelivery === element.fastDelivery){
                        if(element.deliveryCost === 0) return "Gratis"
                        return `${reduceDecimalValue(element.deliveryCost, 2)} USD`
                    }
                }
                return "GRATIS"
            }
            if(!productUser) return "GRATIS"
            if(productUser.deliveryCosts && productUser.deliveryCosts.length > 0){
                for(const province of productUser.deliveryCosts){
                    if(province.provinceName === address.region){
                        if(province.available){
                            if(province.isFree?.always) return "GRATIS"
                            if(province.isFree?.above) {
                                const isAbove = checkAbovePassed(province.isFree.aboveNumber)
                                if(isAbove){
                                    return "GRATIS"
                                }
                            }
                            for(const municipality of province.municipalities){
                                if(municipality.name === address.municipality){
                                    if(municipality.cost === 0) return "GRATIS"
                                    return `${reduceDecimalValue(municipality.cost, 2)} USD`
                                }
                            }
                        }
                        else{
                            return "NO ENTREGA EN SU PROVINCIA"
                        }
                    }
                }
            }
            return "GRATIS"
        }

        const walletUseDeliveryCost = (productUser, address, fastDelivery) => {
            if(order?.deliveryCostsPerUser?.length > 0){
                for (let index = 0; index < order.deliveryCostsPerUser.length; index++) {
                    const element = order.deliveryCostsPerUser[index];
                    if(element.user._id === productUser._id && fastDelivery === element.fastDelivery){
                        if(element.payed.wallet === 0) return false
                        return true
                    }
                }
                return false
            }
            return false
        }

        const getWalletUseDeliveryCost = (productUser, address, fastDelivery) => {
            if(order?.deliveryCostsPerUser?.length > 0){
                for (let index = 0; index < order.deliveryCostsPerUser.length; index++) {
                    const element = order.deliveryCostsPerUser[index];
                    if(element.user._id === productUser._id && fastDelivery === element.fastDelivery){
                        if(element.payed.wallet === 0) return `${reduceDecimalValue(0, 2)} USD`
                        return `${reduceDecimalValue(element.payed.wallet, 2)} USD`
                    }
                }
                return `${reduceDecimalValue(0, 2)} USD`
            }
        }

        const getRemainWalletUseDeliveryCost = (productUser, address, fastDelivery) => {
            if(order?.deliveryCostsPerUser?.length > 0){
                for (let index = 0; index < order.deliveryCostsPerUser.length; index++) {
                    const element = order.deliveryCostsPerUser[index];
                    if(element.user._id === productUser._id && fastDelivery === element.fastDelivery){
                        if(element.payed.wallet === 0) return `${reduceDecimalValue(0, 2)} USD`
                        return `${reduceDecimalValue(element.deliveryCost - element.payed.wallet, 2)} USD`
                    }
                }
                return `${reduceDecimalValue(0, 2)} USD`
            }
        }

        const goToUrl = () => {
            if(element.product.distributorUrl){
                window.open(element.product.distributorUrl)
            }
        }

        return(
            <div>
                {firstDelivery &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            
                            <div style={{flex: 1}}>
                                <Typography className={classes.elementFirstHeader}>ENTREGA RAPIDA</Typography>
                            </div>
                            
                        </div>
                    </div>
                }
                {firstImport &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                <Typography className={classes.elementFirstHeader}>IMPORTACION</Typography>
                            </div>
                        </div>
                    </div>
                }
                {showUser &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            <Typography className={classes.elementHeader}>TIENDA:</Typography>
                            <img src={element.product.user?.profilePictureUrl? element.product.user?.profilePictureUrl : userPlaceholder} className={classes.userImage}/>
                            <Typography className={classes.elementHeader}>{element?.product?.user?.importCompanyName? element.product.user.importCompanyName.toUpperCase() : `${element.product?.user?.name?.toUpperCase()} ${element.product?.user?.lastName?.toUpperCase()}`}</Typography>
                        </div>
                    </div>
                }
                <div className={classes.elementHolder}>
                    <div onClick={() => {toProductPage(element.product._id)}} className={classes.imageContainer}>
                        <img alt="" className={classes.elementImage} src={element.product.mainImageUrl}/>
                    </div>
                    <div className={classes.elementInfoAndPrice}>
                        <div className={classes.elementInfo}>
                            <Typography className={classes.elementText}>{element.product.name.toUpperCase()}</Typography>
                            {(element.size && element.color) &&
                                <React.Fragment>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography className={classes.elementText} style={{fontSize: '11px', paddingRight: '5px'}}>TALLA:</Typography>
                                        <Typography className={classes.elementText} style={{fontSize: '13px', paddingLeft: '0px'}}>{element.size}</Typography>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography className={classes.elementText} style={{paddingRight: '5px', fontSize: '11px'}}>COLOR: </Typography>
                                        <div 
                                            style={{
                                                backgroundColor: element.color, 
                                                width: '17px', 
                                                height: '17px',
                                                borderRadius: '5px', 
                                                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                            }}
                                        />
                                    </div>    
                                </React.Fragment>
                            }   
                            {element.product.shippingBySea &&
                            <div>
                                <Typography className={classes.elementText} style={{fontSize: '15px', color: colors.primary}}>(POR BARCO)</Typography>
                            </div>
                            }
                            {element.product.fastDelivery?
                            <div>
                                {(userLogged?.isImportAdmin || (productStatus === "Recibido") || (productStatus === "Entregado") || (productStatus === "No Entregado")) &&
                                    <Typography className={classes.elementText} style={{fontSize: '12px', color: getCurrentStatusProductColor(element)}}>({productStatus}){element.trackStatus?.message? `:${element.trackStatus.message}` : ""}</Typography>
                                }
                            </div>
                            :
                            <div>
                                {(userLogged?.isImportAdmin || (productStatus === "No Entregado") || (productStatus === "Recibido") || (productStatus === "Entregado")) &&
                                    <Typography className={classes.elementText} style={{fontSize: '12px', color: getCurrentStatusProductImportColor(productStatus)}}>({productStatus}){element.trackStatus?.message? `:${element.trackStatus.message}` : ""}</Typography>
                                }
                            </div>
                            }
                            {element.deliveryCalendar ? 
                                <div className={classes.calendarHolder}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Today className={classes.leftInfoIcon} />
                                        <Typography style={{fontSize: '12px', fontWeight: '600'}}>FECHA/HORA ENTREGA</Typography>
                                    </div>
                                    <Typography style={{fontSize: '13px', fontWeight: '600', paddingLeft: '24px'}}>{getDeliveryCalendarString({...element.deliveryCalendar, allowed: true})}</Typography>
                                </div>
                                :
                                <React.Fragment>
                                    {element.product.fastDelivery && 
                                        <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}>
                                            <img src={b2_truck} style={{height: '12px', width: 'auto', marginTop: '4px', marginRight: '3px'}}/><Typography style={{fontSize: '12px', fontWeight: '600'}}>ENTREGA INMEDIATA</Typography>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                        <div className={classes.priceInfo}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <AttachMoney className={classes.leftInfoIconFilled}/>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '20px'}}>PRECIO</Typography>
                                </div>
                                <div>
                                    <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product, order.sendingCost), 2)} `}<span className={classes.currencyPrice}>USD</span></Typography>
                                    {order.hasDiscountCoupon && <Typography className={classes.rightInfoText} style={{fontSize: '13px'}}>-{`${getProductDiscountForCoupon(element.product, order.discountCouponData.productDiscount)} `}<span className={classes.currencyPrice}>USD</span></Typography>}
                                    {order.hasDiscountCoupon && <Typography className={classes.rightInfoText} style={{fontSize: '9px'}}>(DESC POR CUPON)</Typography>}  
                                    {order.hasDiscountCoupon && <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product)-getProductDiscountForCoupon(element.product, order.discountCouponData.productDiscount))} `}<span className={classes.currencyPrice}>USD</span></Typography>}   
                                </div>
                            </div>
                            
                            {user.isImportAdmin &&
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Score className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>PUNTOS</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{element.product.fastDelivery? 0 : reduceDecimalValue(element.product.points, 0, true)}</Typography>
                            </div>
                            }
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <SwapVert className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '29.5px'}}>CANT.</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{element.count}</Typography>
                            </div>
                            {user.isImportAdmin &&
                            <div onClick={goToUrl} style={{display: 'flex', alignItems: 'center', marginTop: '2px', cursor: element.product.distributorUrl? 'pointer' : 'inherit'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Person className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '31px'}}>DIST.</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{(element.product?.fastDelivery || !element.product?.distributor?.name)? (element.product.user?.name + ' ' + element.product.user?.lastName) : element.product.distributor?.name}</Typography>
                            </div>
                            }
                            
                        </div>
                    </div>
                    <div className={classes.countAndPriceHolder}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '15px'}}>
                            <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>SUBTOTAL</Typography>
                            <Typography className={element?.payed?.wallet > 0? classes.elementUnit : classes.elementUnitBlue} style={{whiteSpace: 'nowrap', fontSize: element?.payed?.wallet? "14px" : "18px"}}>{getSubtotalValue(element.product, element.count, order.hasDiscountCoupon, 0)} <span className={classes.currencyPrice}>USD</span></Typography>
                            {element?.payed?.wallet > 0 &&
                                <React.Fragment>
                                    <div style={{display: 'flex', alignItems: 'end'}}>
                                        <AccountBalanceWallet className={classes.rowInfoIconSecondary} style={{marginRight: '-1px'}}/> 
                                        <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap', fontSize: "14px"}}>-{reduceDecimalValue(element.payed.wallet)} <span className={classes.currencyPrice}>USD</span></Typography>
                                    </div>
                                    <Typography className={classes.elementUnitBlue} style={{whiteSpace: 'nowrap'}}>{getSubtotalValue(element.product, element.count, order.hasDiscountCoupon, element.payed.wallet)} <span className={classes.currencyPrice}>USD</span></Typography>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    
                </div>
                <div className={classes.priceInfoResponsive}>
                    <div style={{flex: 1}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{display :'flex', alignItems: 'center'}}>
                                <AttachMoney className={classes.leftInfoIconFilled}/>
                                <Typography className={classes.leftInfoTag} style={{marginRight: '10px'}}>PRECIO</Typography>
                            </div>
                            <div>
                                <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product, order.sendingCost), 2)} `}<span className={classes.currencyPrice}>USD</span></Typography>
                                {order.hasDiscountCoupon && <Typography className={classes.rightInfoText} style={{fontSize: '13px'}}>-{`${getProductDiscountForCoupon(element.product, order.discountCouponData.productDiscount)} `}<span className={classes.currencyPrice}>USD</span></Typography>}
                                {order.hasDiscountCoupon && <Typography className={classes.rightInfoText} style={{fontSize: '9px'}}>(DESC POR CUPON)</Typography>}  
                                {order.hasDiscountCoupon && <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product)-getProductDiscountForCoupon(element.product, order.discountCouponData.productDiscount))} `}<span className={classes.currencyPrice}>USD</span></Typography>}   
                            </div>
                        </div>
                        {/* {!element.product.fastDelivery &&
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                            <div style={{display :'flex', alignItems: 'center'}}>
                                <Flight className={classes.leftInfoIconFilled}/>
                                <Typography className={classes.leftInfoTag} style={{marginRight: '8px'}}>IMPORT</Typography>
                            </div>
                            <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(calcSendingCost(element.product, order.sendingCost))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                        </div>
                        } */}
                        {user.isImportAdmin &&
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                            <div style={{display :'flex', alignItems: 'center'}}>
                                <Score className={classes.leftInfoIcon}/>
                                <Typography className={classes.leftInfoTag}  style={{marginRight: '6px'}}>PUNTOS</Typography>
                            </div>
                            <Typography className={classes.rightInfoText}>{element.product.fastDelivery? 0 : reduceDecimalValue(element.product.points, 0, true)}</Typography>
                        </div>
                        }
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <SwapVert className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '29.5px'}}>CANT.</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{element.count}</Typography>
                            </div>
                            {user.isImportAdmin &&
                            <div onClick={goToUrl} style={{display: 'flex', alignItems: 'center', marginTop: '2px', cursor: element.product.distributorUrl? 'pointer' : 'inherit'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Person className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '31px'}}>DIST.</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{(element.product?.fastDelivery || !element.product?.distributor?.name)? (element.product.user?.name + ' ' + element.product.user?.lastName) : element.product.distributor?.name}</Typography>
                            </div>
                            }
                    </div>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: '5px', marginRight: '20px', alignItems: 'end'}}>
                        <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>SUBTOTAL</Typography>
                        <Typography className={element?.payed?.wallet > 0? classes.elementUnit : classes.elementUnitBlue} style={{whiteSpace: 'nowrap', fontSize: element?.payed?.wallet? "14px" : "18px"}}>{getSubtotalValue(element.product, element.count, order.hasDiscountCoupon, 0)} <span className={classes.currencyPrice}>USD</span></Typography>
                        {element?.payed?.wallet > 0 &&
                            <React.Fragment>
                                <div style={{display: 'flex', alignItems: 'end'}}>
                                    <AccountBalanceWallet className={classes.rowInfoIconSecondary} style={{marginRight: '-1px'}}/> 
                                    <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap', fontSize: "14px"}}>-{reduceDecimalValue(element.payed.wallet)} <span className={classes.currencyPrice}>USD</span></Typography>
                                </div>
                                <Typography className={classes.elementUnitBlue} style={{whiteSpace: 'nowrap'}}>{getSubtotalValue(element.product, element.count, order.hasDiscountCoupon, element.payed.wallet)} <span className={classes.currencyPrice}>USD</span></Typography>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className={classes.lightGrayDividerElement} />
                {addDeliveryCost&&
                <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                    <img src={sendCostIcon} className={classes.airTaxImage}/>
                    <div style={{flex: 1}}>
                        <Typography className={classes.elementHeader}>COSTO DE ENTREGA</Typography>
                        <Typography className={classes.elementSecondary}>TIENDA: {element?.product?.user?.importCompanyName? element.product.user.importCompanyName.toUpperCase() : `${element.product?.user?.name?.toUpperCase()} ${element.product?.user?.lastName?.toUpperCase()}`}</Typography>
                    </div>
                    <div>
                        <Typography className={walletUseDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery)? classes.elementUnit : classes.elementUnitBlue}>{getDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery)}</Typography>
                        {walletUseDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery) &&
                            <div style={{display: 'flex', alignItems: 'end'}}>
                                <AccountBalanceWallet className={classes.rowInfoIconSecondary} style={{marginRight: '-1px'}}/>
                                <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap', fontSize: "14px"}}>-{getWalletUseDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery)}</Typography>
                            </div>
                        }
                        {walletUseDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery) &&
                            <Typography className={classes.elementUnitBlue}>{getRemainWalletUseDeliveryCost(element.product.userConfirmed, address, element.product?.fastDelivery)}</Typography>
                        }
                    </div>
                </div>
                }
                
                {addDeliveryCost&&
                    <div className={classes.lightGrayDividerElement} style={{marginBottom: '50px'}}/>
                }
            </div>
            
        )
    }

    const isFirstImport = (index, products) => {
        if(index === 0 && !products[index].product.fastDelivery) return true
        if(index-1 >= 0 && !products[index].product.fastDelivery && products[index-1].product.fastDelivery) return true
        return false
    }
    const isFirstDelivery = (index, products) => {
        if(index === 0 && products[index].product.fastDelivery) return true
        return false 
    }
    const isDeliveryCostItem = (index, products) => {
        try{
            const count = products.length
            if(index === count - 1) return true
            if(products[index].product.user._id !== products[index+1].product.user._id) return true
            else{
                if(products[index].product.fastDelivery && !products[index+1].product.fastDelivery) return true
            }
            return false
        }
        catch(e){
            console.log(e)
            console.log("ORDEN: ", order._id, " PRODUCTO: ", products[index].product.name)
            return false
        }
    }
    const isShowUser = (index, products) => {
        try{
            if(index === 0) {
                return true
            }
            if(!products[index].product.user) return true
            if(products[index].product.user._id !== products[index-1].product.user._id) return true
            else{
                if(!products[index].product.fastDelivery && products[index-1].product.fastDelivery) return true
            }
            return false
        }
        catch(e){
            console.log(e)
            console.log("ORDEN: ", order._id, " PRODUCTO: ", products[index].product.name)
            return false
        }
    }

    const getOrderUserName = () => {
        let name = ""
        if(order?.user?.name) name += order.user.name
        if(order?.user?.lastName) name = name + " " + order.user.lastName
        return name.toUpperCase()
    }

    const canGetNextState = () => {
        //!!!!CHEQUEAR SI PUEDO OBTENER SIGUIENTE ESTADO
        if(currentStatus === "Solicitado") return true
        return false
    }

    const canBeCanceled = () => {
        //!!!!CHEQUEAR SI PUEDE SER CANCELADO
        if(currentStatus !== "Cancelado") return true
        return false
    }
    
  
    return (
      <React.Fragment>
        <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
            <Alert onClose={handleCloseApiErrorSnack} severity="error">
                No se pudo cambiar el estado. Revise su conexión a Internet.
            </Alert>
        </Snackbar>
        <Snackbar open={openUnavailableErrorSnack} autoHideDuration={3000} onClose={handleCloseUnavailableErrorSnack}>
            <Alert onClose={handleCloseUnavailableErrorSnack} severity="warning">
                Hay productos esperando a chequear Disponibilidad o no disponibles.
            </Alert>
        </Snackbar>
        <Snackbar open={snackProps.open} autoHideDuration={5000} onClose={handleCloseSnackProps}>
            <Alert onClose={handleCloseSnackProps} severity={snackProps.severity}>
                {snackProps.text}
            </Alert>
        </Snackbar>
        <Snackbar open={openUndeliveredErrorSnack} autoHideDuration={3000} onClose={handleCloseUndeliveredErrorSnack}>
            <Alert onClose={handleCloseUndeliveredErrorSnack} severity="warning">
                Hay productos de entrega rapida que no se han entregado.
            </Alert>
        </Snackbar>
        <Snackbar open={openUndeliveredImportErrorSnack} autoHideDuration={3000} onClose={handleCloseUndeliveredImportErrorSnack}>
            <Alert onClose={handleCloseUndeliveredImportErrorSnack} severity="warning">
                Hay productos de importacion que que no se han entregado en el almacen.
            </Alert>
        </Snackbar>
        <Snackbar open={openSuccessSnack} autoHideDuration={2000} onClose={handleCloseSuccessSnack}>
            <Alert onClose={handleCloseSuccessSnack} severity="success">
                Estado cambiado correctamente.
            </Alert>
        </Snackbar>
        <Snackbar open={openEditSuccessSnack} autoHideDuration={2000} onClose={handleCloseEditSuccessSnack}>
            <Alert onClose={handleCloseEditSuccessSnack} severity="success">
                Orden Editada correctamente.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteSuccessSnack} autoHideDuration={1000} onClose={handleCloseDeleteSuccessSnack}>
            <Alert onClose={handleCloseDeleteSuccessSnack} severity="success">
                Se eliminó la orden.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteApiErrorSnack} autoHideDuration={2000} onClose={handleCloseDeleteApiErrorSnack}>
            <Alert onClose={handleCloseDeleteApiErrorSnack} severity="error">
                No se pudo eliminar orden. Revise su conexión a Internet.
            </Alert>
        </Snackbar>
        <Modal
            open={openModal.open}
            onClose={handleClose}
            aria-labelledby="next order state"
            aria-describedby="data necessary to process to next state"
        >
            <div className={classes.modalPaper}>
                <Typography className={classes.mainText}>Siguiente Estado</Typography>
                <Typography>Seguro desea pasar la orden al estado: {getNextState()}?</Typography>
                {currentStatus === 'Confirmado' && !allFastDelivery &&
                <React.Fragment>
                <Typography style={{marginTop: '7px'}}>Guia House</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setGuideHouseError('')
                            }}
                        elevation={4}
                        placeholder="########"
                        value={guideHouse}
                        onChange={handleChangeGuideHouse}
                        type='number'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:20 }}
                    />
                    {guideHouseError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleGuideHouseErrorTooltipClose}
                                open={guideHouseErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={guideHouseError}
                            >
                                <IconButton onClick={handleGuideHouseErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                
                </React.Fragment>
                }
                {currentStatus === 'Solicitado' &&
                <React.Fragment>
                <Typography style={{marginTop: '7px'}}>Observacion de Pago</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setPaymentTypeObservation('')
                            }}
                        elevation={4}
                        placeholder="USD, MLC..."
                        multiline
                        value={paymentTypeObservation}
                        onChange={handleChangePaymentObservation}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:200 }}
                    />
                    {paymentTypeObservationError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handlePaymentObservationErrorTooltipClose}
                                open={paymentTypeObservationErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={paymentTypeObservationError}
                            >
                                <IconButton onClick={handlePaymentObservationErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                
                </React.Fragment>
                }
                <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                    <Button variant='contained' onClick={() => {setOpenModal({open:false, makeDiscount:false})}} className={classes.confirmButton}>No</Button>
                    {updatingStateOrder?
                        <Button
                        className={classes.makeOrderButton} style={{width: '118px'}}
                        classes={{
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        ><CircularProgress size={24} style={{color: 'white'}}/></Button>
                    :
                        <Button variant='contained' onClick={onClickNextState} className={classes.makeOrderButton}>Confirmar</Button>
                    }
                </div>
            </div>
        </Modal>
        <Modal
            open={openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="edit order Guide House"
            aria-describedby="data necessary to process to next state"
        >
            <div className={classes.modalPaper}>
                <Typography className={classes.mainText}>Editar Orden #{order.orderNumber} de {order.user.name + ' ' + order.user.lastName}</Typography>
                <React.Fragment>
                <Typography style={{marginTop: '7px'}}>GUIA HOUSE</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setGuideHouseError('')
                            }}
                        elevation={4}
                        placeholder="########"
                        value={guideHouse}
                        onChange={handleChangeGuideHouse}
                        type='number'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:20 }}
                    />
                    {guideHouseError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleGuideHouseErrorTooltipClose}
                                open={guideHouseErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={guideHouseError}
                            >
                                <IconButton onClick={handleGuideHouseErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                </React.Fragment>
                <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                    <Button variant='contained' onClick={() => {setOpenEditModal(false)}} className={classes.confirmButton}>No</Button>
                    {updatingEditOrder?
                        <Button
                        className={classes.makeOrderButton} style={{width: '118px'}}
                        classes={{
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        ><CircularProgress size={24} style={{color: 'white'}}/></Button>
                    :
                        <Button variant='contained' onClick={onClickEditOrder} className={classes.makeOrderButton}>Confirmar</Button>
                    }
                </div>
            </div>
        </Modal>
        <div className={classes.rowHolder}>
            <div className={classes.rowStatusHolder} style={{backgroundColor: getCurrentStatusColor(), border: `2.5px solid ${getCurrentStatusColor()}`}}>
                <Typography className={classes.statusText}>{currentStatus.toUpperCase()} <span style={{fontSize: '12px'}}>({getDate(currentStatusDate)})</span></Typography>
            </div>
            <div className={classes.rowOrderInfoHolder} style={{border: `2.5px solid ${getCurrentStatusColor()}`}}>
                <div style={{display: 'flex'}}>
                    <div className={classes.rowUserAddresseeAndProductsSummarize}>
                        <div className={classes.rowUserAndAddresseeInfo}>
                            <div className={classes.rowUserInfo}>
                                <div className={classes.rowUserSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Assignment className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>#{order.orderNumber}</Typography>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px', }}>
                                        <MonetizationOn className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{getPaymentTypeName(order?.paymentType)}</Typography>
                                    </div>
                                </div>
                                {(user.isSuperAdmin && order?.currencyData?.currencyName && order?.currencyData?.currencyName !== 'usd' && order?.currencyData?.exchangeValue) &&
                                    <div className={classes.rowUserSubInfo}>
                                        <div style={{display: 'flex', alignItems: 'center' }}>
                                            <EmojiSymbols className={classes.rowInfoIcon}/>
                                            <Typography className={classes.rowInfoText}>
                                                {order.currencyData.currencyName.toUpperCase()} {'('}{reduceDecimalValue(order.currencyData.exchangeValue, 2)}{')'}  {'->'} <span style={{fontSize: '15px'}}>{reduceDecimalValue(getTotalOrder(order)*order.currencyData.exchangeValue, 2)}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                }
                                <div className={classes.rowUserSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center', cursor: (user.isSuperAdmin || user.isImportAdmin)? 'pointer' : 'text'}} onClick={() => {if(user.isSuperAdmin || user.isImportAdmin) history.push(`/orders/${order.user?._id}`)}}>
                                        <AccountCircle className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{getOrderUserName()}</Typography>
                                        
                                    </div>
                                </div>
                                <div className={classes.rowUserSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Email className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order.user?.email}</Typography>
                                    </div>
                                </div>
                                <div className={classes.rowUserSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Phone className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order.user?.phone}</Typography>
                                    </div>
                                </div>
                                
                            </div>
                            <div className={classes.rowUserAndAddresseeDivider} />
                            <div className={`${classes.rowAddresseeInfo} ${classes.rowAddresseeInfoHideXs}`}>
                                <div className={classes.rowAddresseeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center' }}>
                                        <AssignmentInd className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order?.addressee?.name?.toUpperCase()}</Typography>
                                    </div>
                                </div>
                                <div className={classes.rowAddresseeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <CreditCard className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order?.addressee?.ci}</Typography>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px', }}>
                                        <Phone className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order?.addressee?.phone}</Typography>
                                    </div>
                                </div>
                                <div className={classes.rowAddresseeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                        <PersonPinCircle className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{order?.addressee?.address?.toUpperCase()}</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.rowUserAddresseeAndProductsSummarizeDivider} />
                        <div className={classes.rowProductSummarizeAndOptions}>
                            <div className={classes.rowProductSummarize}>
                                <div className={classes.rowProductSummarizeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                        <Typography className={classes.rowInfoText}>{order.productsCount} PRODUCTO{order.productsCount > 1? 'S' : ''}</Typography>
                                        {/* PONER AQUI PENDIENTES */}
                                    </div>
                                </div>
                                {notDelivers.count > 0 &&
                                <div className={classes.rowProductSummarizeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                        <Typography className={classes.rowInfoText} style={{color: colors.redError}}>{notDelivers.count} NO ENTREGADO{notDelivers.count > 1? 'S' : ''}</Typography>
                                    </div>
                                </div>
                                }
                                <div className={classes.rowProductSummarizeSubInfo}>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                        <Typography className={classes.rowInfoTextMain}>TOTAL:</Typography>
                                        <AttachMoney className={classes.rowInfoIconMain}/>
                                        <Typography className={classes.rowInfoTextMain}>{getTotalOrder(order)} USD</Typography>
                                    </div>
                                </div>
                                <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '3px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                        <Typography className={classes.rowInfoText}>PRODUCTOS:</Typography>
                                        <AttachMoney className={classes.rowInfoIcon}/>
                                        <Typography className={classes.rowInfoText}>{reduceDecimalValue(order.totalProductsPrice, 2)} USD</Typography>
                                    </div>
                                </div>
                                {notDelivers.count > 0 &&
                                    <div className={classes.rowProductSummarizeSubInfo}>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                            <Typography className={classes.rowInfoTextSecondary}>NO ENTREGADOS:</Typography>
                                            <Cancel className={classes.rowInfoIconSecondary}/>
                                            <Typography className={classes.rowInfoTextSecondary}>-{reduceDecimalValue(notDelivers.price - notDelivers.wallet - notDelivers.discount, 2)} USD</Typography>
                                        </div>
                                    </div>
                                }
                                {order.totalProductsCouponDiscount > 0 &&
                                    <div className={classes.rowProductSummarizeSubInfo}>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                            <Typography className={classes.rowInfoTextSecondary}>DESCUENTO:</Typography>
                                            <LocalOffer className={classes.rowInfoIconSecondary}/>
                                            <Typography className={classes.rowInfoTextSecondary}>-{reduceDecimalValue(order.totalProductsCouponDiscount, 2)} USD</Typography>
                                        </div>
                                    </div>
                                }
                                
                                {order.totalDeliveryCost > 0 &&
                                    <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '3px'}}>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                            <Typography className={classes.rowInfoText}>ENTREGA:</Typography>
                                            <LocalShipping className={classes.rowInfoIcon}/>
                                            <Typography className={classes.rowInfoText}>{reduceDecimalValue(order.totalDeliveryCost, 2)} USD</Typography>
                                        </div>
                                    </div>
                                }
                                {order.airWaybill > 0 &&
                                    <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '3px'}}>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                            <Typography className={classes.rowInfoText}>IMPUESTO AEREO:</Typography>
                                            <Flight className={classes.rowInfoIcon}/>
                                            <Typography className={classes.rowInfoText}>{reduceDecimalValue(order.airWaybill, 2)} USD</Typography>
                                        </div>
                                    </div>
                                }
                                {order.walletPayment > 0 &&
                                    <div className={classes.rowProductSummarizeSubInfo}>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                                            <Typography className={classes.rowInfoText}>USO CARTERA:</Typography>
                                            <AccountBalanceWallet className={classes.rowInfoIconSecondary}/>
                                            <Typography className={classes.rowInfoText}>-{reduceDecimalValue(order.walletPayment, 2)} USD</Typography>
                                        </div>
                                    </div>
                                }
                                <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '5px', marginBottom: '8px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', border: `1.5px solid ${colors.secondary}`, paddingLeft: '5px', paddingRight: '5px', cursor: 'pointer', marginLeft: 'auto'}} onClick={() => {setOpenDetails(!openDetails)}}>
                                        {openDetails?
                                            <VisibilityOff className={classes.rowInfoIcon}/>
                                            :
                                            <Visibility className={classes.rowInfoIcon}/>
                                        }
                                        <Typography className={classes.rowInfoText}>{openDetails? "OCULTAR DETALLES" : "VER DETALLES"}</Typography>
                                    </div>
                                </div>
                                {(order.paymentType === "OnlineStripe" && currentStatus === "Solicitado") &&
                                    <div className={classes.rowProductSummarizeSubInfo} style={{marginTop: '5px', marginBottom: '8px'}}>
                                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '5px', paddingRight: '5px', marginLeft: 'auto'}}>
                                            {gettingCheckoutUrl?
                                            <Button
                                            className={classes.makeOrderButton} style={{width: '82px', height: '32px', marginRight: '0px'}}
                                            classes={{
                                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                            }}
                                            ><CircularProgress size={24} style={{color: colors.whiteAppbar}}/></Button>
                                            :
                                            <Button variant='contained' 
                                                onClick={() => {getCheckoutUrl()}} className={classes.makeOrderButton} style={{height: '32px', marginRight: '0px'}}
                                                disabled={gettingCheckoutUrl}>PAGAR</Button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes.rowUserAddresseeAndProductsSummarizeDivider} />
                    <div className={classes.rowOptions}>
                        <div>
                            <div>
                                <IconButton variant='contained' onClick={() => {onClickAsk(order._id)}}  
                                className={classes.askButton}
                                >
                                    <ContactSupport />
                                </IconButton>
                            </div>
                            {isImportAdmin && canGetNextState() &&
                                <div>
                                    <IconButton variant='contained' onClick={() => {onClickConfirm(order._id)}}  
                                    className={classes.nextStateButton}
                                    >
                                        <KeyboardTab />
                                    </IconButton>
                                </div>
                            }
                            {/* OPCIONES COMO EDITAR DIRECCION DE ENTREGA */}
                            {(user.isSuperAdmin && (currentStatus === 'Confirmado' || currentStatus === 'Solicitado')) &&
                            <div>
                                <IconButton  variant='contained' onClick={handleClickUserOptions} 
                                className={classes.makeDiscountButton}
                                >
                                    <MoreVert />
                                </IconButton>
                                <StyledMenu
                                    id="long-menu-user"
                                    anchorEl={anchorElUserOptions}
                                    keepMounted
                                    open={openUserOptions}
                                    onClose={() => handleCloseUserOptions(null)}
                                    PaperProps={{
                                    style: {
                                    },
                                    }}
                                >    
                                    {(currentStatus === 'Confirmado' || currentStatus === 'Solicitado') &&
                                    <MenuItem style={{fontSize: '14px'}} key={0} onClick={() => {handleCloseUserOptions()}}
                                    >
                                        EDITAR DIRECCION DE ENTREGA
                                    </MenuItem>
                                    }
                                </StyledMenu>
                            </div>
                            }
                            {(user.isSuperAdmin && canBeCanceled()) &&
                                <React.Fragment>
                                    <DeleteOrderModal openDeleteModal={openDeleteModal} orderId={order._id} setOpenSuccesSnack={OpenDeleteSuccessSnack} setOpenApiErrorSnack={OpenDeleteApiErrorSnack} closeDeleteModalParent={closeDeleteModal}/>
                                    <div>
                                        <IconButton>
                                            <DeleteForever
                                                onClick={() => {setOpenDeleteModal(true)}}
                                                className={classes.deleteButton} 
                                            />
                                        </IconButton>
                                    </div>
                                </React.Fragment>
                            }
                            </div>
                    </div>
                </div>
                <Collapse in={openDetails} timeout="auto" unmountOnExit>
                    <div style={{width: '90%', marginLeft: '5%', height: '1px', backgroundColor: alpha(colors.secondary, 0.8), marginTop: '20px'}}/>
                    <div className={classes.deliveryDirectionXs}>
                        <Typography style={{fontSize: '20px', fontWeight: '600', color: colors.secondary, paddingLeft: '10px'}}>
                            DIRECCION DE ENTREGA
                        </Typography>
                        <div className={classes.rowAddresseeInfo}>
                            <div className={classes.rowAddresseeSubInfo}>
                                <div style={{display: 'flex', alignItems: 'center' }}>
                                    <AssignmentInd className={classes.rowInfoIcon}/>
                                    <Typography className={classes.rowInfoText}>{order?.addressee?.name?.toUpperCase()}</Typography>
                                </div>
                            </div>
                            <div className={classes.rowAddresseeSubInfo}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CreditCard className={classes.rowInfoIcon}/>
                                    <Typography className={classes.rowInfoText}>{order?.addressee?.ci}</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px', }}>
                                    <Phone className={classes.rowInfoIcon}/>
                                    <Typography className={classes.rowInfoText}>{order?.addressee?.phone}</Typography>
                                </div>
                            </div>
                            <div className={classes.rowAddresseeSubInfo}>
                                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                    <PersonPinCircle className={classes.rowInfoIcon}/>
                                    <Typography className={classes.rowInfoText}>{order?.addressee?.address?.toUpperCase()}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: '60px'}}>
                        {orderProducts.map((product, index) => {
                                return <ElementCard element={product} index={index} address={order.addressee} addDeliveryCost={isDeliveryCostItem(index, orderProducts)} showUser={isShowUser(index, orderProducts)} firstImport={isFirstImport(index, orderProducts)} firstDelivery={isFirstDelivery(index, orderProducts)} products={orderProducts} order={order} />
                            }
                        )}
                        {order.airWaybill > 0 &&
                            <div>
                                <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                                    <img src={airTaxImage} className={classes.airTaxImage}/>
                                    <div style={{flex: 1}}>
                                        <Typography className={classes.elementHeader}>IMPUESTO AEREO AIR WAYBILL</Typography>
                                        {/* <Typography className={classes.elementSecondary}>TOTAL PUNTOS MENOR A {allowedAduanaPoints/2/2} = {reduceDecimalValue((guideCut/2)/2, 2)}USD</Typography>
                                        <Typography className={classes.elementSecondary}>TOTAL DE PUNTOS ENTRE {allowedAduanaPoints/2/2} Y {allowedAduanaPoints/2} = {reduceDecimalValue(guideCut/2, 2)}USD</Typography>
                                        <Typography className={classes.elementSecondary}>TOTAL PUNTOS MAYOR A {allowedAduanaPoints/2} = {reduceDecimalValue(guideCut, 2)}USD</Typography> */}
                                        {/* <Typography className={classes.elementSecondary}>{discountOverTotalPriceMessage.toUpperCase()} = GRATIS</Typography> */}
                                    </div>
                                    <Typography className={classes.elementUnitBlue}>{reduceDecimalValue(order.airWaybill, 2)} USD</Typography>
                                </div>
                                <div className={classes.divisionSummarize}/>
                            </div>
                        }
                        {/* {order.walletPayment > 0 &&
                            <div>
                                <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                                    <img src={walletImage} className={classes.walletImage}/>
                                    <div style={{flex: 1, marginLeft: '-5px'}}>
                                        <Typography className={classes.elementHeader}>USO DE SALDO DE MI CARTERA</Typography>
                                    </div>
                                    <Typography className={classes.elementUnit}>-{reduceDecimalValue(order.walletPayment, 2)} USD</Typography>
                                </div>
                                <div className={classes.lightGrayDividerElement} />
                            </div>
                        } */}
                    </div>
                </Collapse>
            </div>
        </div>
        
      </React.Fragment>
    );
  }

